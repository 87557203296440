.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  text-align: center;
  vertical-align: middle;

  & > .icons {
    line-height: normal;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  & > i {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
  }

  & > img {
    width: 100%;
    vertical-align: top;
  }

  &.avatar-6 {
    line-height: 6px;
    height: 6px;
    width: 6px;
  }

  &.avatar-10 {
    line-height: 10px;
    height: 10px;
    width: 10px;
  }

  &.avatar-15 {
    line-height: 15px;
    height: 15px;
    width: 15px;
  }

  &.avatar-20 {
    line-height: 20px;
    height: 20px;
    width: 20px;
  }

  &.avatar-30 {
    line-height: 30px;
    height: 30px;
    width: 30px;
  }

  &.avatar-36 {
    line-height: 36px;
    height: 36px;
    width: 36px;
  }

  &.avatar-40 {
    line-height: 40px;
    height: 40px;
    width: 40px;
  }

  &.avatar-44 {
    line-height: 44px;
    height: 44px;
    width: 44px;
  }

  &.avatar-50 {
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  &.avatar-60 {
    line-height: 60px;
    height: 60px;
    width: 60px;
  }

  &.avatar-70 {
    line-height: 70px;
    height: 70px;
    width: 70px;
  }

  &.avatar-80 {
    line-height: 80px;
    height: 80px;
    width: 80px;
  }

  &.avatar-90 {
    line-height: 90px;
    height: 90px;
    width: 90px;
  }

  &.avatar-100 {
    line-height: 100px;
    height: 100px;
    width: 100px;
  }

  &.avatar-110 {
    line-height: 110px;
    height: 110px;
    width: 110px;
  }

  &.avatar-120 {
    line-height: 120px;
    height: 120px;
    width: 120px;
  }

  &.avatar-130 {
    line-height: 130px;
    height: 130px;
    width: 130px;
  }

  &.avatar-140 {
    line-height: 140px;
    height: 140px;
    width: 140px;
  }

  &.avatar-150 {
    line-height: 150px;
    height: 150px;
    width: 150px;
  }

  &.avatar-160 {
    line-height: 160px;
    height: 160px;
    width: 160px;
  }

  &.avatar-250 {
    line-height: 250px;
    height: 250px;
    width: 250px;
  }

  &.avatar-300 {
    line-height: 300px;
    height: 300px;
    width: 300px;
  }
}

.avatar-group {
  z-index: 1;
  display: flex;

  .avatar {
    z-index: 1;
    margin-left: -20px;

    @for $i from 1 through 7 {
      &:nth-child(#{$i}) {
        $value: 7 - $i;
        z-index: $value;
      }
    }
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
