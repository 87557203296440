.price-text {
    font-weight: 600;
}

.font-weight-600 {
    font-weight: 600;
}

.dashedBorder {
    border: 1px dashed;
    padding: 7px;
    text-transform: uppercase;
    font-weight: bold;
    width: 50%;
}

.close-btn {
    top: -9px;
    left: 2px;
}
