.coloren .bubble {
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 4px;
}
.coloren .bubble-1 {
  height: 15px;
  width: 15px;
  top: 21px;
  left: 59px;
}
.coloren .bubble-2 {
  height: 27px;
  width: 27px;
  top: 36px;
  left: 16px;
}
.coloren .bubble-3 {
  height: 21px;
  width: 21px;
  top: 63px;
  left: 49px;
}
.coloren .bubble-4 {
  height: 15px;
  width: 15px;
  top: 98px;
  left: 37px;
}
.coloren .bubble-5 {
  height: 5px;
  width: 5px;
  top: 116px;
  left: 20px;
  background-color: transparent;
  border-style: solid;
}
.coloren .bubble-6 {
  height: 6px;
  width: 6px;
  top: 128px;
  left: 63px;
}
.coloren .bubble-7 {
  height: 27px;
  width: 27px;
  top: 150px;
  left: 52px;
}
.coloren .bubble-8 {
  height: 19px;
  width: 19px;
  top: 154px;
  left: 18px;
}
.coloren .bubble-9 {
  height: 10px;
  width: 10px;
  top: 189px;
  left: 13px;
}
.coloren .bubble-10 {
  height: 5px;
  width: 5px;
  top: 199px;
  left: 52px;
  background-color: transparent;
  border-style: solid;
}
.coloren .bubble-11 {
  height: 21px;
  width: 21px;
  top: 220px;
  left: 29px;
}
.coloren .bubble-12 {
  height: 21px;
  width: 21px;
  top: 263px;
  left: 48px;
}
.coloren .bubble-13 {
  height: 5px;
  width: 5px;
  top: 275px;
  left: 16px;
  background-color: transparent;
  border-style: solid;
}
.coloren .bubble-14 {
  height: 15px;
  width: 15px;
  top: 296px;
  left: 34px;
}
.coloren .triangle {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}
.coloren .triangle-1 {
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 4px;
  height: 10px;
  width: 10px;
  top: 22px;
  left: 55px;
}
.coloren .triangle-2 {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 14px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 15px;
  top: 27px;
}
.coloren .triangle-2:after {
  content: " ";
  display: block;
  position: absolute;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid transparent;
  top: 6px;
  left: -3.5px;
  transition: border-bottom-color 0.4s ease;
}
.coloren .red .triangle-2:after {
  border-bottom-color: #fc5c82;
}
.coloren .red .triangle-3:after {
  border-bottom-color: #fc5c82;
}
.coloren .red .triangle-14:after {
  border-bottom-color: #fc5c82;
}
.coloren .yellow .triangle-2:after {
  border-bottom-color: #fcd45c;
}
.coloren .yellow .triangle-3:after {
  border-bottom-color: #fcd45c;
}
.coloren .yellow .triangle-14:after {
  border-bottom-color: #fcd45c;
}
.coloren .purple .triangle-2:after {
  border-bottom-color: #9174f5;
}
.coloren .purple .triangle-3:after {
  border-bottom-color: #9174f5;
}
.coloren .purple .triangle-14:after {
  border-bottom-color: #9174f5;
}
.coloren .triangle-3 {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 19px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 43px;
  top: 60px;
}
.coloren .triangle-3:after {
  content: " ";
  display: block;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 9px solid transparent;
  top: 6px;
  left: -4.75px;
  transition: border-bottom-color 0.4s ease;
}
.coloren .triangle-4 {
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 3px;
  height: 8px;
  width: 8px;
  top: 61px;
  left: 17px;
  background-color: transparent;
  border-style: solid;
}
.coloren .triangle-5 {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 25px;
  top: 101px;
  transform: rotate(180deg);
}
.coloren .triangle-6 {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 60px;
  top: 103px;
  transform: rotate(-90deg);
}
.coloren .triangle-7 {
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
  border-bottom: 19px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 17px;
  top: 126px;
  transform: rotate(180deg);
}
.coloren .triangle-8 {
  border-left: 10.5px solid transparent;
  border-right: 10.5px solid transparent;
  border-bottom: 16px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 50px;
  top: 149px;
}
.coloren .triangle-9 {
  border-left: 5.5px solid transparent;
  border-right: 5.5px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 21px;
  top: 177px;
}
.coloren .triangle-10 {
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 4px;
  height: 10px;
  width: 10px;
  top: 177px;
  left: 60px;
}
.coloren .triangle-11 {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 33px;
  top: 213px;
  transform: rotate(180deg);
}
.coloren .triangle-12 {
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 2px;
  height: 10px;
  width: 10px;
  top: 233px;
  left: 65px;
  background-color: transparent;
  border-style: solid;
}
.coloren .triangle-13 {
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 4px;
  height: 10px;
  width: 10px;
  top: 250px;
  left: 22px;
}
.coloren .triangle-14 {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 14px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  left: 45px;
  top: 270px;
  transform: rotate(180deg);
}
.coloren .triangle-14:after {
  content: " ";
  display: block;
  position: absolute;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid transparent;
  top: 6px;
  left: -3.5px;
  transition: border-bottom-color 0.4s ease;
}
.coloren .stick {
  height: 362px;
  width: 90px;
  border-radius: 14px;
  background-image: url(https://greghub.github.io/coloron/public/svg/noise.png);
  position: relative;
  overflow: hidden;
  float: left;
  margin-right: 90px;
  transition: background-color 0.4s ease;
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor.svg), pointer;
}
.coloren .stick .block {
  position: absolute;
  overflow: hidden;
  z-index: 999;
  border-radius: 7px;
}
.coloren .stick .block .inner {
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 3px;
  height: 100%;
  width: 100%;
  position: absolute;
}
.coloren .stick .block:nth-child(2n+1) .inner-2 {
  left: -200%;
}
.coloren .stick .block:nth-child(2n+2) .inner-2 {
  left: 200%;
}
.coloren .stick .block-1 {
  height: 16px;
  width: 31px;
  top: 16px;
  left: 30px;
}
.coloren .stick .block-1 .inner {
  background-color: transparent !important;
  border-style: solid;
  box-sizing: border-box;
}
.coloren .stick .block-2 {
  height: 14px;
  width: 42px;
  top: 50px;
  left: 15px;
}
.coloren .stick .block-3 {
  height: 18px;
  width: 9px;
  top: 73px;
  left: 64px;
}
.coloren .stick .block-4 {
  height: 9px;
  width: 14px;
  top: 84px;
  left: 26px;
}
.coloren .stick .block-5 {
  height: 15px;
  width: 15px;
  top: 109px;
  left: 45px;
}
.coloren .stick .block-5 .inner {
  border-radius: 50%;
}
.coloren .stick .block-6 {
  height: 9px;
  width: 27px;
  top: 135px;
  left: 19px;
}
.coloren .stick .block-7 {
  height: 12px;
  width: 12px;
  top: 144px;
  left: 60px;
}
.coloren .stick .block-7 .inner {
  border-radius: 50%;
  border-style: solid;
  box-sizing: border-box;
  background-color: transparent;
}
.coloren .stick .block-8 {
  height: 27px;
  width: 14px;
  top: 164px;
  left: 24px;
}
.coloren .stick .block-9 {
  height: 8px;
  width: 8px;
  top: 188px;
  left: 64px;
}
.coloren .stick .block-10 {
  height: 12px;
  width: 22px;
  top: 219px;
  left: 11px;
}
.coloren .stick .block-10 .inner {
  background-color: transparent !important;
  border-style: solid;
  box-sizing: border-box;
}
.coloren .stick .block-11 {
  height: 22px;
  width: 22px;
  top: 226px;
  left: 50px;
}
.coloren .stick .block-11 .inner {
  border-radius: 50%;
}
.coloren .stick .block-12 {
  height: 18px;
  width: 9px;
  top: 248px;
  left: 26px;
}
.coloren .stick .block-13 {
  height: 8px;
  width: 8px;
  top: 278px;
  left: 50px;
}
.coloren .stick .block-13 .inner {
  border-radius: 50%;
}
.coloren .stick .block-14 {
  height: 12px;
  width: 22px;
  top: 297px;
  left: 18px;
}
.coloren .stick .block-14 .inner {
  background-color: transparent !important;
  border-style: solid;
  box-sizing: border-box;
}
.coloren .stick .block-15 {
  height: 9px;
  width: 27px;
  top: 307px;
  left: 48px;
}
.coloren .stick:focus {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor-tap.svg), pointer;
}
.coloren .stick:active {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor-tap.svg), pointer;
}
.coloren body {
  background-color: #28dad4;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}
.coloren a {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor.svg), pointer;
}
.coloren a:focus {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor-tap.svg), pointer;
}
.coloren a:active {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor-tap.svg), pointer;
}
.coloren .container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.coloren .waves {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.coloren .waves div {
  position: absolute;
  width: 100%;
}
.coloren .mounts {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.coloren .mounts div {
  position: absolute;
  width: 100%;
}
.coloren .clouds {
  position: absolute;
  width: 100%;
  left: 0;
  top: 77px;
  height: 151px;
  background: url(https://greghub.github.io/coloron/public/svg/clouds.svg) repeat-x;
  background-position-x: 170px;
}
.coloren .top_wave {
  background: url(https://greghub.github.io/coloron/public/svg/top_wave.png) repeat-x 0 -1px;
  height: 35px;
  bottom: 0;
  z-index: 10001;
}
.coloren .wave1 {
  background: url(https://greghub.github.io/coloron/public/svg/wave1.svg) repeat-x;
  height: 150px;
  bottom: 0;
  z-index: 23;
}
.coloren .wave2 {
  background: url(https://greghub.github.io/coloron/public/svg/wave2.svg) repeat-x;
  height: 180px;
  bottom: 30px;
  z-index: 22;
}
.coloren .wave3 {
  background: url(https://greghub.github.io/coloron/public/svg/wave3.svg) repeat-x;
  height: 180px;
  bottom: 90px;
  z-index: 21;
}
.coloren .wave4 {
  background: url(https://greghub.github.io/coloron/public/svg/wave4.svg) repeat-x;
  height: 180px;
  bottom: 120px;
  z-index: 20;
}
.coloren .mount1 {
  background: url(https://greghub.github.io/coloron/public/svg/mount1.svg) repeat-x;
  height: 150px;
  bottom: 280px;
  z-index: 11;
}
.coloren .mount2 {
  background: url(https://greghub.github.io/coloron/public/svg/mount2.svg) repeat-x;
  height: 150px;
  bottom: 290px;
  z-index: 10;
}
.coloren .noise {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1010;
  background: url(https://greghub.github.io/coloron/public/svg/noise.png);
}
.coloren .glow {
  position: absolute;
  left: -350px;
  top: -350px;
  width: 800px;
  height: 800px;
  background-color: rgba(81, 237, 200, 0.34);
  border-radius: 50%;
  box-shadow: 0 0 100px 100px rgba(81, 237, 200, 0.34);
  z-index: 1010;
}
.coloren .sun {
  position: relative;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 1px;
  background-color: #ffe345;
  border-radius: 50%;
  box-shadow: 0 0 32px 32px #ffe345, 0 0 150px 150px rgba(103, 244, 210, 0.4);
}
.coloren .small-glow {
  z-index: 99;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.34);
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.34);
}
.coloren .small-glow.yellow {
  background-color: rgba(255, 227, 69, 0.34);
  box-shadow: 0 0 4px 4px rgba(255, 227, 69, 0.34);
}
.coloren .sticks {
  z-index: 1011;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.coloren .stick.red {
  background-color: #ff4571;
}
.coloren .stick.yellow {
  background-color: #ffd145;
}
.coloren .stick.purple {
  background-color: #8260f6;
}
.coloren .stick.inactive {
  background-color: #4c4660;
}
.coloren .ball {
  background: url(https://greghub.github.io/coloron/public/svg/ball.svg) right bottom;
  background-size: 64px 64px;
  width: 53px;
  height: 53px;
  z-index: 1011;
  background-color: #ff4571;
  border-radius: 50%;
  margin-bottom: 250px;
}
.coloren .ball-demo {
  background: url(https://greghub.github.io/coloron/public/svg/ball.svg) right bottom;
  background-size: 64px 64px;
  width: 53px;
  height: 53px;
  z-index: 1011;
  background-color: #ff4571;
  border-radius: 50%;
}
.coloren .controls {
  z-index: 999999;
  position: relative;
}
.coloren .game-full-flex {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
}
.coloren .start-game .start-game-top {
  min-height: 20%;
}
.coloren .start-game .start-game-top .play-full-page {
  display: none;
  border: 3px solid #fff;
  border-radius: 100px;
  color: #fff;
  width: 260px;
  height: 50px;
  font-size: 28px;
  text-align: center;
  font-weight: 900;
  letter-spacing: -1px;
  line-height: 52px;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 24px;
}
.coloren .start-game .start-game-top .play-full-page:hover {
  opacity: 0.7;
}
.coloren .start-game .logo-holder {
  width: 513px;
  height: 162px;
  background-color: #4c4660;
  border: 4px solid #ff4571;
  border-radius: 68px;
  text-align: center;
  margin-top: -10%;
}
.coloren .start-game .logo-holder .logo {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 100px;
  letter-spacing: -0.1em;
  margin-top: 10px;
  margin-bottom: 4px;
  text-align: center;
}
.coloren .start-game .logo-holder .logo span {
  margin-left: -8px;
  margin-right: -8px;
}
.coloren .start-game .logo-holder .play-button {
  display: inline-block;
  background-color: #ff4571;
  border: 4px solid #fff;
  border-radius: 100px;
  color: #fff;
  width: 200px;
  height: 56px;
  font-size: 42px;
  text-align: center;
  font-weight: 900;
  letter-spacing: -3px;
  line-height: 56px;
  text-decoration: none;
}
.coloren .start-game .logo-holder .play-button:hover {
  background-color: #ff5f84;
}
.coloren .start-game .logo-holder .hint {
  color: black;
  font-size: 20px;
}
.coloren .start-game .logo-holder .hint span {
  color: #ff4571;
}
.coloren .start-game .how-to-play {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.coloren .start-game .how-to-play .section-1 {
  flex: 1;
}
.coloren .start-game .how-to-play .section-1 .content {
  justify-content: center;
}
.coloren .start-game .how-to-play .section-3 {
  flex: 1;
}
.coloren .start-game .how-to-play .section-3 .content {
  justify-content: center;
}
.coloren .start-game .how-to-play .section-3 .ball-demo {
  background-color: #815ff8;
}
.coloren .start-game .how-to-play .section-3 .bar-1 {
  height: 120px;
  background-color: #815ff8;
}
.coloren .start-game .how-to-play h4 {
  color: black;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
}
.coloren .start-game .how-to-play .content {
  height: 200px;
  position: relative;
  display: flex;
  justify-content: space-around;
}
.coloren .start-game .how-to-play .bar {
  width: 60px;
  border-radius: 7px;
  margin-top: auto;
  transition: background-color 0.4s ease;
}
.coloren .start-game .how-to-play .bar.bar-1 {
  height: 180px;
  background: #ff4571;
}
.coloren .start-game .how-to-play .bar.bar-2 {
  height: 120px;
  background: #ffd145;
}
.coloren .start-game .how-to-play .bar.bar-3 {
  height: 150px;
  background: #4c4660;
}
.coloren .start-game .how-to-play .section-2 .bar {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor.svg), pointer;
}
.coloren .start-game .how-to-play .section-2 .bar:focus {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor-tap.svg), pointer;
}
.coloren .start-game .how-to-play .section-2 .bar:active {
  cursor: url(https://greghub.github.io/coloron/public/svg/cursor-tap.svg), pointer;
}
.coloren .stop-game {
  justify-content: center;
}
.coloren .stop-game .score-container {
  background-color: #4c4660;
  width: 433px;
  height: 386px;
  border-radius: 38px;
  text-align: center;
}
.coloren .stop-game .score-container h1 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: -0.1em;
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 64px;
}
.coloren .stop-game .score-container .final-score {
  color: #ffe345;
  font-weight: 900;
  font-size: 130px;
  letter-spacing: -6px;
  line-height: 110px;
}
.coloren .stop-game .score-container .result {
  color: #ff4571;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
}
.coloren .stop-game .score-container h4 {
  color: #fff;
  margin-top: 12px;
}
.coloren .stop-game .score-container .tweet {
  background: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  color: #55acee;
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
}
.coloren .stop-game .score-container .tweet:hover {
  background-color: #55acee;
  color: #fff;
}
.coloren .stop-game .score-container .tweet i {
  font-size: 24px;
  top: 2px;
  right: 2px;
  position: relative;
}
.coloren .stop-game .score-container .play-again {
  background-color: #ff4571;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 26px;
  padding: 6px 24px;
  border-radius: 22px;
  margin: 6px 4px;
  display: inline-block;
}
.coloren .stop-game .score-container .play-again:hover {
  background-color: #ff5f84;
}
.coloren .stop-game .score-container .main-menu {
  background-color: #44bfa3;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 26px;
  padding: 6px 24px;
  border-radius: 22px;
  margin: 6px 4px;
  display: inline-block;
}
.coloren .stop-game .score-container .main-menu:hover {
  background-color: #57c6ac;
}
.coloren .scene {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 9997;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.coloren .scene .ball-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 558px;
}
.coloren .scene .score {
  position: fixed;
  right: 54px;
  top: 20px;
  color: #33485f;
  font-size: 90px;
  font-weight: 900;
  letter-spacing: -0.1em;
}
.coloren .scene .learn-to-play {
  z-index: 9999;
  display: inline-block;
  text-align: center;
  position: relative;
  top: 20%;
  font-size: 48px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 700;
  letter-spacing: -2px;
  opacity: 0;
}
.coloren .splash {
  display: none;
}
.coloren .nominee {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}
@media print {
  .coloren .splash {
    display: block;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #28dad4;
    background-image: url(https://greghub.github.io/coloron/public/images/coloron-image.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
 }
}
