:root {
  --bg-color: #fff;
  --headings-color: #000000;
  --desc-color: #000000;
}

body[data-theme="dark-theme"] {
  --bg-color: #000000;
  --headings-color: #fff;
  --desc-color: #fff;
}

body[data-theme="sea-theme"] {
  --bg-color: #ffe699;
  --headings-color: #96ceb4;
  --desc-color: #ff9292;
}

body[data-theme="sharp-theme"] {
  --bg-color: #ff7777;
  --headings-color: #a2416b;
  --desc-color: #852747;
}

body {
  background: var(--bg-color) !important;
}

.content-container {
  margin-top: 5rem;
}

.hero-section-headings {
  padding-top: 3rem;
}

.heroImg {
  width: 100%;
  height: 100%;
}

.hearo-section-title {
  margin-bottom: 16px;
  color: var(--headings-color);
}

.select-theme-heading {
  color: var(--headings-color);
}

.hero-section-desc {
  margin-right: 5rem;
  color: var(--desc-color);
}

.select-theme-div .btn {
  margin-right: 1rem;
  width: 100px;
  margin-top: 16px;
}

#hero-btn1 {
  background-color: black;
  border: 1px solid black;
  box-shadow: none;
}

#hero-btn2 {
  background-color: white;
  border: 1px solid black;
  color: black;
  box-shadow: none;
}

#hero-btn3 {
  background-color: #0099cc;
  border: 1px solid #0099cc;
  color: white;
  box-shadow: none;
}

#hero-btn4 {
  background-color: #d67d3e;
  border: 1px solid #d67d3e;
  color: black;
  box-shadow: none;
}


.footer .nav .nav-item.centerbutton .nav-link>span{
  display: flex;
  justify-content: center;
  align-items: center
  
}





@import url("https://fonts.googleapis.com/css2?family=Silkscreen&display=swap");



.games.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0px !important;
}
/* 
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
  margin-top: 0.5rem;
}

.footer_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 370px;
  color: #fff;
}

.footer_container p {
  font-size: 0.6em;
}

.footer_container p {
  color: greenyellow;
}

footer .mode_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  margin-right: 0.5em;
}

footer .mode_container > span {
  color: red;
  font-size: 0.65em;
  margin-top: 0.4em;
}

footer .btn_container > button {
  position: relative;
  width: 30px;
  height: 11px;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  margin: 0.5em 0;
}

footer .btn_container > button > .circle {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f2f2f2;
  cursor: pointer;
  transition: all 200ms ease-in;
}

footer .btn_container > button .active {
  transition: all 200ms ease-in;
  left: 0;
} */
.sidebar-wrap .sidebar .nav .nav-item .nav-link>.icon {
  margin-right: 15px;
  border-radius: calc(var(--oneuiux-rounded) - 5px);
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .modal-dialog,.modal.show .modal-dialog{
  margin: 0px !important;
} */
.swiper-wrapper{
  height: auto !important;
}
.modal-video .modal-dialog{
  width: 90% !important;
  height: 40% !important;
}
.modal-video .modal-content{
  height: 100% !important;
}
@media only screen and (min-width: 940px) {
  iframe{width: 500px !important;
   height: 400px !important;
   border-radius: 15px;}
 }
 .fade.modal.show{
  
background-color: #80808057;
 }