.text-muted {
    opacity: 0.7;
    color: inherit !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 600;
    margin-bottom: 0;

    &:last-child {
        margin-bottom: 0;
    }
}

h1,
.h1 {
    font-size: 40px;
    line-height: 1.15;
}

h2,
.h2 {
    font-size: 34px;
    line-height: 1.15;
}

p {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.small,
small {
    line-height: 1.22;
}

strong {
    font-weight: 600;
}

a {
    text-decoration: none;
}

a,
.text-color-theme {
    color: var(--oneuiux-theme-color);
}

.text-secondary {
    color: var(--oneuiux-theme-text-secondary) !important;
}

.fw-medium {
    font-weight: 600;
}

.dark-bg {
    color: var(--oneuiux-theme-text);

    a,
    .text-color-theme {
        color: var(--oneuiux-theme-text);
    }

    .form-control,
    label {
        color: #000000;
    }
}

.text-normalcase {
    text-transform: none !important;
}

@media screen and (max-width: 375px) {

    h1,
    .h1 {
        font-size: 32px;
    }

    h2,
    .h2 {
        font-size: 28px;
    }
}