.header {
    padding: var(--oneuiux-padding);
    line-height: 44px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 8;
    position: relative;

    &>* {
        z-index: 1;
        position: relative;
    }

    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 0 0 var(--oneuiux-rounded) var(--oneuiux-rounded);
        background-color: var(--oneuiux-header-active);
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        -webkt-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        z-index: 0;
        opacity: 0;
        @extend .transition-05;
    }

    &.active,
    &.header-filled {
        color: var(--oneuiux-theme-text-primary) !important;

        &:after {
            opacity: 0.95;
        }

        &>* {
            z-index: 1;
            position: relative;
        }
    }

    p {
        line-height: 20px;
    }

    &.shadow-none {
        &:after {
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
        }
    }

    &.border-bottom {
        border: none !important;

        &:after {
            border-bottom: 1px solid var(--oneuiux-theme-bordercolor);
        }
    }
}