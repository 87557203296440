/* thank you*/
.imgbottom {
    margin-top: -300px;

    .imgtop {
        height: 340px;
        margin: 0 auto;
        position: relative;
        max-width: 320px;
        width: 100%;
    }
}


@media screen and (max-height: 800px) {
    .imgbottom {
        margin-top: -170px;

        .imgtop {
            height: 200px;
        }
    }
}