/* body {
	margin: 0;
	background-color: #000;
	background-image: radial-gradient(ellipse at top, #335476 0%, #31506e 11.1%, #304b67 22.2%, #2f4760 33.3%, #2d4359 44.4%, #2c3f51 55.6%, #2a3a4a 66.7%, #293643 77.8%, #28323d 88.9%, #262e36 100%) !important;
	height: 100vh;
	overflow: hidden;
	font-family: monospace;
	font-weight: bold;
	letter-spacing: 0.06em;
	color: rgba(255, 255, 255, 0.75);
} */
.menja{
	margin: 0;
	background-color: #000;
	background-image: radial-gradient(ellipse at top, #335476 0%, #31506e 11.1%, #304b67 22.2%, #2f4760 33.3%, #2d4359 44.4%, #2c3f51 55.6%, #2a3a4a 66.7%, #293643 77.8%, #28323d 88.9%, #262e36 100%) !important;
	height: 100vh;
	overflow: hidden;
	font-family: monospace;
	font-weight: bold;
	letter-spacing: 0.06em;
	color: rgba(255, 255, 255, 0.75);
}
.menja #c {
	display: block;
	touch-action: none;
	transform: translateZ(0);
}
.menja .hud__score {
	position: fixed;
	font-size: calc(14px + 2vw + 1vh);
	top: 0.65em;
	left: 0.65em;
	pointer-events: none;
	user-select: none;
}
.menja .pause-btn {
	position: fixed;
	font-size: calc(14px + 2vw + 1vh);
	position: fixed;
	top: 0;
	right: 0;
	padding: 0.8em 0.65em;
}
.menja .pause-btn > div {
	position: relative;
	width: 0.8em;
	height: 0.8em;
	opacity: 0.75;
}
.menja .pause-btn > div::before {
	content: '';
	display: block;
	width: 34%;
	height: 100%;
	position: absolute;
	background-color: #fff;
}
.menja .pause-btn > div::after {
	content: '';
	display: block;
	width: 34%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	right: 0;
}
.menja .cube-count-lbl {
	font-size: 0.46em;
}
.menja .slowmo {
	position: fixed;
	bottom: 0;
	width: 100%;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.4s;
	will-change: opacity;
}
.menja .slowmo::before {
	content: 'SLOW-MO';
	display: block;
	font-size: calc(8px + 1vw + 0.5vh);
	margin-left: 0.5em;
	margin-bottom: 8px;
}
.menja .slowmo::after {
	content: '';
	display: block;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 1.5vh;
	/* background-color: rgba(0, 0, 0, 0.25); */
	z-index: -1;
}
.menja .slowmo__bar {
	height: 1.5vh;
	background-color: rgba(255, 255, 255, 0.75);
	transform-origin: 0 0;
}
.menja .menus::before {
	content: '';
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	opacity: 0;
	transition: opacity 0.2s;
	transition-timing-function: ease-in;
}
.menja .menus:not(.interactive-mode) .menu.active > * {
	pointer-events: auto;
}
.menja .menus.has-active::before {
	opacity: 0.08;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
}
.menja .menus.interactive-mode::before {
	opacity: 0.02;
}
.menja .menus.interactive-mode .menu.active {
	opacity: 0.6;
}
.menja .menu {
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	user-select: none;
	text-align: center;
	color: rgba(255, 255, 255, 0.9);
	opacity: 0;
	visibility: hidden;
	transform: translateY(30px);
	transition-property: opacity, visibility, transform;
	transition-duration: 0.2s;
	transition-timing-function: ease-in;
}
.menja .menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
}
.menja h1 {
	font-size: 4rem;
	line-height: 0.95;
	text-align: center;
	font-weight: bold;
	margin: 0 0.65em 1em;
}
.menja h2 {
	font-size: 1.2rem;
	line-height: 1;
	text-align: center;
	font-weight: bold;
	margin: -1em 0.65em 1em;
}
.menja .final-score-lbl {
	font-size: 5rem;
	margin: -0.2em 0 0;
}
.menja .high-score-lbl {
	font-size: 1.2rem;
	margin: 0 0 2.5em;
}
.menja button {
	display: block;
	position: relative;
	width: 200px;
	padding: 12px 20px;
	background: transparent;
	border: none;
	outline: none;
	user-select: none;
	font-family: monospace;
	font-weight: bold;
	font-size: 1.4rem;
	color: #fff;
	opacity: 0.75;
	transition: opacity 0.3s;
}
.menja button::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	/* background-color: rgba(255, 255, 255, 0.15); */
	transform: scale(0, 0);
	opacity: 0;
	transition: opacity 0.3s, transform 0.3s;
}
.menja button:active {
	opacity: 1;
}
.menja button:active::before {
	transform: scale(1, 1);
	opacity: 1;
}
.menja .credits {
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 20px;
}
.menja a {
	color: white;
}
@media (min-width: 1025px) {
	.menja button:hover {
		opacity: 1;
   }
	.menja button:hover::before {
		transform: scale(1, 1);
		opacity: 1;
   }
}
