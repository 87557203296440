/*
==================================
Table of content 
==================================

1. variable
2. Bootstrap default css
3. utility
4. text
5. body
6. button
7. form element
8. loader
9. header
10. avatar
11. sidebar
12. card
13. swiper sliders
14. list 
15. footer
16. dark mode
17. filter
18. Full calendar
19. Logo
20. Tabs
21. Tags
22. Thank you
23. RTL

*/



/*!
 * Bootstrap v5.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
// scss-docs-end import-stack



/*!
 oneuiux customize css starts here 
 * oneuiux v2 (https://maxartkiller.com/)
 * Copyright 2021 Maxartkiller 
 */

/* 2. variables */
@import "variables";

/* 3. utility */
@import "utility";

/* 4. text */
@import "text";

/* 5. body */
@import "body";

/* 6. button */
@import "button";

/* 7. Form elements */
@import "_form";

/* 8. loader */
@import "loader";

/* 9. header */
@import "header";

/* 10. avatar */
@import "avatar";

/* 11. sidebar */
@import "sidebar";

/* 12. card */
@import "card_toast_modal";

/* 13. swiper sliders */
@import "swiperslides";

/* 14. list */
@import "list";

/* 15. footer */
@import "footer";

/* 16. footer */
@import "darkmode";

/* 17. Filter */
@import "filter";

/* 18. Calendar */
@import "fullcalendar";

/* 19. Logo */
@import "logo";

/* 20. tabs layout */
@import "tabs";

/* 21. tags */
@import "tags";

/* 22. Tahank you */
@import "thankyou";

/* 23. RTL layout */
@import "rtl";

/* 24. Custom */
@import "custom";