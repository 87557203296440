/*
==================================
Table of content 
==================================

1. variable
2. Bootstrap default css
3. utility
4. text
5. body
6. button
7. form element
8. loader
9. header
10. avatar
11. sidebar
12. card
13. swiper sliders
14. list 
15. footer
16. dark mode
17. filter
18. Full calendar
19. Logo
20. Tabs
21. Tags
22. Thank you
23. RTL

*/
/*!
 * Bootstrap v5.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/*!
 oneuiux customize css starts here 
 * oneuiux v2 (https://maxartkiller.com/)
 * Copyright 2021 Maxartkiller 
 */
/* 2. variables */
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

:root {
  --oneuiux-rounded: 20px;
  --oneuiux-padding: 15px;
  --oneuiux-input-rounded: 10px;
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #57bdff;
  --oneuiux-theme-color-grad-2: #4f77fb;
  --oneuiux-theme-color-grad-3: #6528cf;
  /* color schemes */
  --oneuiux-theme-color: #0d6efd;
  --oneuiux-theme-text: #ffffff;
  --oneuiux-theme-text-primary: #000000;
  --oneuiux-theme-text-secondary: #999999;
  --oneuiux-theme-text-secondary-light: #bbbbbb;
  --oneuiux-theme-bordercolor: rgba(0, 0, 0, 0.1);
  --oneuiux-header: transparent;
  --oneuiux-header-active: #ffffff;
  --oneuiux-footer: #ffffff;
  --oneuiux-sidebar: var(--oneuiux-theme-color);
  --oneuiux-card-color: #ffffff;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #eff6f9;
  --oneuiux-page-bg-3: #faf0fb;
  --oneuiux-page-text: #000000;
  --oneuiux-page-link: var(--oneuiux-theme-color);
}

.theme-indigo {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #8b9dff;
  --oneuiux-theme-color-grad-2: #5c00f1;
  --oneuiux-theme-color-grad-3: #68008e;
  /* color schemes */
  --oneuiux-theme-color: #6610f2;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #f1eff9;
  --oneuiux-page-bg-3: #f6f0fb;
}

.theme-purple {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #e908d8;
  --oneuiux-theme-color-grad-2: #5a2ab3;
  --oneuiux-theme-color-grad-3: #300284;
  /* color schemes */
  --oneuiux-theme-color: #6f42c1;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #fff5fc;
  --oneuiux-page-bg-3: #f6f0fb;
}

.theme-pink {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ff71c3;
  --oneuiux-theme-color-grad-2: #e80075;
  --oneuiux-theme-color-grad-3: #4d00da;
  /* color schemes */
  --oneuiux-theme-color: #E50A8E;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #f4f8ff;
  --oneuiux-page-bg-3: #ffe9f7;
}

.theme-red {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffc699;
  --oneuiux-theme-color-grad-2: #ff5d83;
  --oneuiux-theme-color-grad-3: #dc006a;
  /* color schemes */
  --oneuiux-theme-color: #FF5465;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #fffbf2;
  --oneuiux-page-bg-3: #ffeeee;
}

.theme-orange {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffeb7b;
  --oneuiux-theme-color-grad-2: #ffc400;
  --oneuiux-theme-color-grad-3: #ff7300;
  /* color schemes */
  --oneuiux-theme-color: #fd7e14;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #fffaf4;
  --oneuiux-page-bg-3: #fff4eb;
}

.theme-yellow {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffdb6f;
  --oneuiux-theme-color-grad-2: #f0ac00;
  --oneuiux-theme-color-grad-3: #f03000;
  /* color schemes */
  --oneuiux-theme-color: #ffc107;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #f9f4ef;
  --oneuiux-page-bg-3: #fbf8f0;
}

.theme-green {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #bdffaa;
  --oneuiux-theme-color-grad-2: #04c5de;
  --oneuiux-theme-color-grad-3: #00a0f5;
  /* color schemes */
  --oneuiux-theme-color: #21E985;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #eff6f9;
  --oneuiux-page-bg-3: #e6f7f0;
}

.theme-teal {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #a2fffa;
  --oneuiux-theme-color-grad-2: #00a2ad;
  --oneuiux-theme-color-grad-3: #3f51b5;
  /* color schemes */
  --oneuiux-theme-color: #20c997;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #fff7fd;
  --oneuiux-page-bg-3: #e8faf8;
}

.theme-cyan {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #74e5fb;
  --oneuiux-theme-color-grad-2: #26F7FF;
  --oneuiux-theme-color-grad-3: #2482FB;
  /* color schemes */
  --oneuiux-theme-color: #00C2FF;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #f1eff9;
  --oneuiux-page-bg-3: #f0f8fb;
}

.theme-gold {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffe74c;
  --oneuiux-theme-color-grad-2: #f4c91b;
  --oneuiux-theme-color-grad-3: #ffb900;
  /* color schemes */
  --oneuiux-theme-color: #f4c91b;
  --oneuiux-page-bg-1: #ffffff;
  --oneuiux-page-bg-2: #f9f7eb;
  --oneuiux-page-bg-3: #f8f8f8;
}

@media screen and (max-width: 375px) {
  :root {
    --oneuiux-padding: 10px;
    --oneuiux-rounded: 12px;
  }
}

/* 3. utility */
.modal-dialog-ends {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1rem);
}

.border-left-5,
.card.border-left-5 {
  border-left-width: 5px;
  border-left-style: solid;
}

.dashed-line {
  border-top: 1px dashed rgba(0, 0, 0, 0.25);
  min-height: 1px;
}

.blur {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
}

.bg-blur {
  -moz-backdrop-filter: saturate(120%) blur(15px);
  -webkit-backdrop-filter: saturate(120%) blur(15px);
  backdrop-filter: saturate(120%) blur(15px);
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-99 {
  z-index: 99;
}

.bottom-50 {
  margin-bottom: 50px;
}

.bottom-100 {
  margin-bottom: 100px;
}

.top-130 {
  margin-top: -130px;
}

.top-140 {
  margin-top: -140px;
}

.top-160 {
  margin-top: -160px;
}

.top-170 {
  margin-top: -170px;
}

.top-230 {
  margin-top: -230px;
}

.top-240 {
  margin-top: -240px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.btn-close,
.accordion-button::after {
  background-size: 50%;
  background-position: center center;
}

.coverimg {
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}

.coverimg+div {
  position: relative;
  z-index: 1;
}

.coverimg+div+div {
  position: relative;
  z-index: 1;
}

.coverimg.right-center-img {
  background-size: auto;
  background-position: left center;
  background-repeat: no-repeat;
}

.coverimg.right-center-img {
  background-size: auto;
  background-position: right center;
  background-repeat: no-repeat;
}

.covervid {
  display: flex;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}

.covervid>*:first-child {
  max-height: 100%;
  width: auto;
  vertical-align: middle;
  align-self: center;
}

.covervid+div {
  position: relative;
  z-index: 1;
}

.covervid+div+div {
  position: relative;
  z-index: 1;
}

.covervid.right-center-img {
  background-size: auto;
  background-position: left center;
  background-repeat: no-repeat;
}

.covervid.right-center-img {
  background-size: auto;
  background-position: right center;
  background-repeat: no-repeat;
}

.overlay {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: block;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 42%, rgba(0, 0, 0, 0.65) 100%);
}

.transition-05,
.filter,
.footer .nav .nav-item.squarebtn .nav-link>span i,
.footer .nav .nav-item.centerbutton .nav-link>span i,
.footer,
/* .sidebar-wrap.sidebar-pushcontent+main, */
.sidebar-wrap .sidebar,
.sidebar-wrap,
.header:after,
.bar-more>span {
  transition: ease all 0.5s;
}

.transition-1,
.sidebar-wrap.sidebar-overlay::after {
  transition: ease all 1s;
}

.rounded-10 {
  border-radius: calc(var(--oneuiux-rounded) - 10px) !important;
}

.rounded-10-end {
  border-radius: 0 calc(var(--oneuiux-rounded) - 5px) calc(var(--oneuiux-rounded) - 5px) 0;
}

.rounded-12 {
  border-radius: calc(var(--oneuiux-rounded) - 8px);
}

.rounded-15 {
  border-radius: calc(var(--oneuiux-rounded) - 5px) !important;
}

.rounded-15-end {
  border-radius: 0 calc(var(--oneuiux-rounded) - 5px) calc(var(--oneuiux-rounded) - 5px) 0;
}

.rounded-18 {
  border-radius: calc(var(--oneuiux-rounded) - 2px);
}

.rounded-20 {
  border-radius: var(--oneuiux-rounded) !important;
}

.rounded-top-left-50 {
  border-top-left-radius: 50px !important;
}

.rounded-top-right-50 {
  border-top-right-radius: 50px !important;
}

.rounded-bottom-left-50 {
  border-bottom-left-radius: 50px !important;
}

.rounded-bottom-right-50 {
  border-bottom-right-radius: 50px !important;
}

.rounded-top-left-40 {
  border-top-left-radius: 40px !important;
}

.rounded-top-right-40 {
  border-top-right-radius: 40px !important;
}

.rounded-bottom-left-40 {
  border-bottom-left-radius: 40px !important;
}

.rounded-bottom-right-40 {
  border-bottom-right-radius: 40px !important;
}

.rounded-top-left-25 {
  border-top-left-radius: 25px !important;
}

.rounded-top-right-25 {
  border-top-right-radius: 25px !important;
}

.rounded-bottom-left-25 {
  border-bottom-left-radius: 25px !important;
}

.rounded-bottom-right-25 {
  border-bottom-right-radius: 25px !important;
}

.h-1 {
  height: 1px;
}

.h-2 {
  height: 2px;
}

.h-4 {
  height: 4px;
}

.h-5 {
  height: 5px;
}

.h-110 {
  height: 110px;
}

.h-140 {
  height: 140px;
}

.h-190 {
  height: 190px;
}

.h-200 {
  height: 200px;
}

.vh-80 {
  height: 80vh !important;
}

.w-10 {
  width: 10px !important;
}

.w-12 {
  width: 12px !important;
}

.w-80 {
  width: 80% !important;
}

.w-110 {
  min-width: 110px;
  width: 110px !important;
}

.size-10 {
  font-size: 10px;
  line-height: 12px;
}

.size-12 {
  font-size: 12px;
}

.size-14 {
  font-size: 14px;
}

.size-18 {
  font-size: 18px;
}

.size-20 {
  font-size: 20px;
}

.size-22 {
  font-size: 22px;
}

.size-24 {
  font-size: 24px;
}

.size-32 {
  font-size: 32px;
}

.opacity-5 {
  opacity: 0.5;
}

.modal-backdrop.show {
  opacity: 0.15;
}

.vm {
  vertical-align: middle !important;
}

.el-fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  height: 100% !important;
  border-radius: 0 !important;
}

.start-auto {
  left: auto !important;
}

.shadow-success.shadow-sm {
  box-shadow: 0 3px 10px rgba(33, 233, 133, 0.25) !important;
}

.shadow-danger.shadow-sm {
  box-shadow: 0 3px 10px rgba(240, 61, 79, 0.2) !important;
}

.shadow-warning.shadow-sm {
  box-shadow: 0 3px 10px rgba(255, 193, 7, 0.3) !important;
}

.shadow-primary.shadow-sm {
  box-shadow: 0 3px 10px rgba(13, 110, 253, 0.2) !important;
}

.text-purple {
  color: #6f42c1;
}

.shadow-purple.shadow-sm {
  box-shadow: 0 3px 10px rgba(111, 66, 193, 0.2) !important;
}

.shadow-info.shadow-sm {
  box-shadow: 0 3px 10px rgba(0, 194, 255, 0.25) !important;
}

.shadow-secondary.shadow-sm {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
}

.shadow-md {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.aftercircle {
  position: relative;
  background-color: var(--oneuiux-card-color);
}

.aftercircle:after {
  content: "";
  position: absolute;
  height: calc(100% + 30px);
  width: 50px;
  right: -25px;
  border-radius: 50%;
  top: -15px;
  display: block;
  z-index: 1;
  background-color: var(--oneuiux-card-color);
}

.phone3d {
  box-shadow: 0 10px 20px rgba(30, 42, 90, 0.1), inset 4px 4px 6px rgba(0, 0, 0, 0.1), inset -3px -3px 10px rgba(176, 187, 230, 0.8);
  display: inline-block;
  padding: 40px 15px 15px 15px;
  border-radius: 36px;
  background: #fff;
  position: relative;
}

.phone3d:before {
  content: "";
  height: 8px;
  width: 100px;
  position: absolute;
  left: 50%;
  top: 20px;
  background: #f0f2fa;
  margin-left: -50px;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.phone3d:after {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  left: 50%;
  top: 20px;
  background: #f0f2fa;
  margin-left: 60px;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.phone3d iframe {
  border-color: #fff;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  overflow: hidden;
  border: 2px solid #e1e6f5;
  height: 812px;
  width: 375px;
}

code,
pre code {
  color: #d63384;
  white-space: pre-wrap;
}

.clearfix {
  clear: both;
  width: 100%;
  display: flex;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .h-190 {
    height: 305px;
  }
}

@media screen and (max-width: 360px) {
  .ps-sm-0 {
    padding-left: 0px;
  }
}

/* 4. text */
.text-muted {
  opacity: 0.7;
  color: inherit !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  margin-bottom: 0;
}

h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 40px;
  line-height: 1.15;
}

h2,
.h2 {
  font-size: 34px;
  line-height: 1.15;
}

p {
  margin-bottom: 10px;
}

p:last-child {
  margin-bottom: 0;
}

.small,
small {
  line-height: 1.22;
}

strong {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a,
.text-color-theme {
  color: var(--oneuiux-theme-color);
}

.text-secondary {
  color: var(--oneuiux-theme-text-secondary) !important;
}

.fw-medium {
  font-weight: 600;
}

.dark-bg {
  color: var(--oneuiux-theme-text);
}

.dark-bg a,
.dark-bg .text-color-theme {
  color: var(--oneuiux-theme-text);
}

.dark-bg .form-control,
.dark-bg label {
  color: #000000;
}

.text-normalcase {
  text-transform: none !important;
}

@media screen and (max-width: 375px) {

  h1,
  .h1 {
    font-size: 32px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }
}


h1,
.h1 {
  font-size: 24px !important;
}

h2,
.h2 {
  font-size: 22px !important;
}

h3,
.h3 {
  font-size: 20px !important;
}

h4,
.h4,
.fs-5 {
  font-size: 18px !important;
}

h5,
.h5 {
  font-size: 16px !important;
}

h6,
.h6 {
  font-size: 14px !important;
}

button,
.btn {
  font-size: 11px !important;
}

p,
.p {
  font-size: 14px !important;
}


/* 5. body */
html.menu-open {
  overflow: hidden;
}

body {
  font-family: "Almarai", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  overflow: hidden;
}

.theme-radial-gradient,
.circle {
  background-color: var(--oneuiux-theme-color-grad-1);
  background-image: radial-gradient(ellipse at 30% 30%, var(--oneuiux-theme-color-grad-1) 0%, var(--oneuiux-theme-color-grad-2) 50%, var(--oneuiux-theme-color-grad-3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--oneuiux-theme-color-grad-1)", endColorstr="var(--oneuiux-theme-color-grad-3)", GradientType=1);
}

.theme-light-radial-gradient,
.loader-wrap,
body {
  background-color: var(--oneuiux-page-bg-2) !important;
  /* background-image: radial-gradient(ellipse at 30% 30%, var(--oneuiux-page-bg-1) 0%, var(--oneuiux-page-bg-2) 50%, var(--oneuiux-page-bg-3) 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--oneuiux-page-bg-1)", endColorstr="var(--oneuiux-page-bg-3)", GradientType=1);
  background-attachment: fixed;
}

.theme-linear-gradient {
  background-color: var(--oneuiux-theme-color-grad-1);
  background-image: linear-gradient(var(--oneuiux-theme-color-grad-3) 0%, var(--oneuiux-theme-color-grad-3) 100%);
}
.bg-color-system {
  background-color: var(--oneuiux-theme-color) !important;
  opacity: 0.9;
}

.theme-light-linear-gradient {
  background-color: var(--oneuiux-page-bg-2);
  background-image: linear-gradient(var(--oneuiux-page-bg-2) 0%, var(--oneuiux-page-bg-3) 100%);
  background-attachment: fixed;
}

main.bg-1 {
  background-image: url("../img/bg1.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
}

main.bg-2 {
  background-image: url("../img/bg2.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center right;
}

main.bg-3 {
  background-image: url("../img/bg3.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: 35% bottom;
}

.bg-none {
  background-color: transparent;
}

.bg-opac {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.bg-opac-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-opac-80 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bg-opac-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.border-opac {
  border-color: rgba(255, 255, 255, 0.45) !important;
}

.alert-danger,
.card.alert-danger,
.bg-danger-light {
  background-color: #fef1f2;
}

.alert-info,
.card.alert-info,
.bg-info-light {
  background-color: #b7f4e2;
}

.alert-primary,
.card.alert-primary,
.bg-primary-light {
  background-color: #cde1ff;
}

.alert-warning,
.card.alert-warning,
.bg-warning-light {
  background-color: #fff1c9;
}

.alert-success,
.card.alert-success,
.bg-success-light {
  background-color: #d1fae6;
}

.alert-theme,
.card.alert-theme,
.bg-theme-light {
  background-color: var(--oneuiux-theme-color-light);
}

.bg-theme,
.modal-backdrop {
  background: var(--oneuiux-theme-color) !important;
}

.imgModal {
  background: rgba(33, 37, 41, 0.55) !important;
}

.bg-theme-round-opac {
  position: relative;
}

.bg-theme-round-opac.header {
  padding-bottom: calc(var(--oneuiux-padding) + 25px);
}

.bg-theme-round-opac.header:after {
  content: "";
  /* // width: 150%; */
  width: 100%;
  height: calc(100% + 20px);
  position: absolute;
  top: -30px;
  /* // left: -25%; */
  background-color: var(--oneuiux-theme-color);
  z-index: -1;
  opacity: 0.9;
  /* // border-radius: 0 0 50% 50%; */
  border-radius: 0 0 25% 25%;
}

.bg-theme-round-opac.header.active {
  color: var(--oneuiux-theme-text) !important;
}

.bg-theme-round-opac-box {
  overflow-x: hidden;
}

.bg-theme-round-opac-box:after {
  content: "";
  /* // width: 150%; */
  width: 100%;
  height: calc(100% + 20px);
  position: absolute;
  top: -30px;
  /* // left: -25%; */
  background-color: var(--oneuiux-theme-color);
  z-index: -1;
  opacity: 0.9;
  /* // border-radius: 0 0 50% 50%; */
  border-radius: 0 0 25% 25%;
}

.border-dashed {
  border-style: dashed;
}

.circle-small {
  height: 44px;
  width: 44px;
  position: relative;
}

.circle-small>div:first-child {
  position: relative;
  width: 100%;
}

.circle-small>.avatar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  margin: 7px;
}

.chartdoughnut {
  width: 200px;
  margin: 0 auto;
  position: relative;
}

.chartdoughnut>.datadisplay {
  position: absolute;
  left: 0;
  right: 0;
  margin: 35px auto;
  text-align: center;
  display: block;
  border-radius: 100px;
  height: 130px;
  width: 130px;
  padding: 35px 0;
  background-color: var(--oneuiux-theme-text);
}

.smallchart {
  width: 100.4%;
  padding: 0;
  display: block;
  height: 70px;
  margin-bottom: 10px;
  border-radius: 0 0 var(--oneuiux-padding) var(--oneuiux-padding);
}

.areachartsmall {
  width: 80px !important;
  height: 40px;
}

/* theme color */
.bg-blue {
  background-color: #0d6efd;
}

.bg-indigo {
  background-color: #6610f2;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-pink {
  background-color: #E50A8E;
}

.bg-red {
  background-color: #f03d4f;
}

.bg-orange {
  background-color: #fd7e14;
}

.bg-yellow {
  background-color: #ffc107;
}

.bg-green {
  background-color: #21E985;
}

.bg-teal {
  background-color: #20c997;
}

.bg-cyan {
  background-color: #00C2FF;
}

/* splash */
.bg-1-splash {
  height: 100px;
  top: -33px;
  position: absolute;
  z-index: 0;
  left: -20px;
}

radialGradient stop:first-child {
  stop-color: var(--oneuiux-theme-color-grad-1);
}

radialGradient stop:last-child {
  stop-color: var(--oneuiux-theme-color-grad-3);
}

linearGradient stop:first-child {
  stop-color: var(--oneuiux-theme-color-grad-1);
}

linearGradient stop:last-child {
  stop-color: var(--oneuiux-theme-color-grad-3);
}

.circle {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.circle.small {
  height: 34px;
  width: 34px;
}

.bg-2-splash {
  height: auto;
  max-width: 80%;
  bottom: 0px;
  position: absolute;
  z-index: 0;
  right: 0px;
}

.online-status {
  height: 10px;
  width: 10px;
  margin: 12px;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.cardimg {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
}

.cardimg+* {
  position: relative;
  z-index: 1;
}

.iwatchposition {
  position: absolute;
  left: 50%;
  top: -16px;
  margin-left: -45px;
  width: 90px;
}

.burgerposition {
  position: absolute;
  left: 50%;
  top: -16px;
  margin-left: -60px;
  width: 140px;
}

.personoffer {
  position: absolute;
  right: 5%;
  width: 125px;
  bottom: 0;
}

/* webkit - scrollbar*/
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (min-device-width: 300px) and (max-device-width: 1023px) and (orientation: landscape) {
  .col-ld-1 {
    width: 8.3333333333%;
  }

  .col-ld-2 {
    width: 16.6666666667%;
  }

  .col-ld-3 {
    width: 25%;
  }

  .col-ld-4 {
    width: 33.3333333333%;
  }

  .col-ld-5 {
    width: 41.6666666667%;
  }

  .col-ld-6 {
    width: 50%;
  }

  .col-ld-7 {
    width: 58.3333333333%;
  }

  .col-ld-8 {
    width: 66.6666666667%;
  }

  .col-ld-9 {
    width: 75%;
  }

  .col-ld-10 {
    width: 83.3333333333%;
  }

  .col-ld-11 {
    width: 91.6666666667%;
  }

  .col-ld-12 {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  :root {
    --oneuiux-padding: 10px;
    --oneuiux-rounded: 18px;
  }

  body {
    font-size: 15px !important;
  }

  .logo-small img {
    border-radius: var(--oneuiux-rounded) !important;
  }

  .logo-mid img {
    border-radius: var(--oneuiux-rounded) !important;
    border: solid 3px #eee;
  }

  .logo-mid-scrolled img {
    border-radius: var(--oneuiux-rounded) !important;
    border: solid 3px #eee;
  }

  .size-12 {
    font-size: 11px !important;
  }

  .mb-4 {
    margin-bottom: 20px !important;
  }

  .mb-3 {
    margin-bottom: 15px !important;
  }

  .mb-2 {
    margin-bottom: 10px !important;
  }

  .mb-1 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 350px) {
  body {
    font-size: 11px !important;
  }

  figure {
    max-width: 100% !important;
  }

  .logo-small img {
    border-radius: var(--oneuiux-rounded) !important;
  }

  .logo-mid img {
    border-radius: var(--oneuiux-rounded) !important;
    border: solid 2px #eee;
  }

  .logo-mid-scrolled img {
    border-radius: var(--oneuiux-rounded) !important;
    border: solid 2px #eee;
  }

  .size-12 {
    font-size: 11px !important;
  }

  .mb-4 {
    margin-bottom: 18px !important;
  }

  .mb-3 {
    margin-bottom: 12px !important;
  }

  .mb-2 {
    margin-bottom: 8px !important;
  }

  .mb-1 {
    margin-bottom: 2px !important;
  }

  .mt-4 {
    margin-top: 18px !important;
  }

  .mt-3 {
    margin-top: 12px !important;
  }

  .mt-2 {
    margin-top: 8px !important;
  }

  .mt-1 {
    margin-top: 2px !important;
  }


  .mx-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
}

/* 6. button */
.btn {
  font-size: 14px;
  text-transform: uppercase;
  border-radius: calc(var(--oneuiux-rounded) - 5px);
  position: relative;
}

.btn.shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.btn.btn-sm {
  font-size: 14px;
  border-radius: calc(var(--oneuiux-rounded) - 7px);
}

.btn.btn-sm i {
  font-size: 12px;
}

.btn-default:hover,
.btn-default:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--oneuiux-theme-color);
  color: var(--oneuiux-theme-text);
}

.btn i {
  font-size: 18px;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-lg,
.btn-group-lg>.btn {
  font-size: 16px;
  padding: var(--oneuiux-padding) calc(var(--oneuiux-padding) + 5px);
  line-height: 28px;
}

.btn-44 {
  height: 44px;
  line-height: 42px;
  width: 44px;
  padding: 0 !important;
}

.btn-80 {
  width: 80px;
  height: 44px;
  line-height: 21px;
  padding: 0 !important;
}

.btn-26 {
  height: 26px;
  line-height: 24px;
  width: 26px;
  padding: 0 !important;
}

.btn-default {
  background-color: var(--oneuiux-theme-color);
  color: var(--oneuiux-theme-text);
}

.btn-default:active,
.btn-default:hover {
  color: var(--oneuiux-theme-text);
}

.btn-default:disabled {
  background-color: var(--oneuiux-theme-bordercolor);
  color: var(--oneuiux-theme-color);
}

.btn-outline-default {
  /* background-color: var(--oneuiux-theme-text); */
  color: var(--oneuiux-theme-color);
  border: 1px solid var(--oneuiux-theme-color) !important;
}

.btn-outline-default:active,
.btn-outline-default:hover {
  color: var(--oneuiux-theme-text);
  background-color: var(--oneuiux-theme-color);
}

.btn-outline-default:disabled {
  background-color: var(--oneuiux-theme-bordercolor);
  color: var(--oneuiux-theme-color);
}

.btn-light {
  background-color: var(--oneuiux-theme-text);
  color: var(--oneuiux-theme-color);
}

.btn-icon-text {
  padding: 0;
}

.btn-icon-text i {
  height: 60px;
  line-height: 58px;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  border-radius: var(--oneuiux-rounded);
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  margin-bottom: 5px;
}

.btn-icon-text span {
  display: block !important;
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.65);
}

.dark-bg .btn-light {
  background-color: rgba(0, 0, 0, 0.15);
  border-color: rgba(0, 0, 0, 0.05);
  color: var(--oneuiux-theme-text);
}

.dark-bg .btn-default {
  background-color: #000000;
  color: #ffffff;
}

.dark-bg .btn-default:active,
.dark-bg .btn-default:hover {
  color: #ffffff;
}

.count-indicator {
  height: 6px;
  width: 6px;
  border-radius: 6px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f03d4f;
}

.bar-more>span {
  height: 4px;
  width: 80%;
  border-radius: 5px;
  display: inline-block;
  max-width: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.bar-more.collapsed>span {
  height: 6px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.4);
}

.counter-number {
  border: 1px solid var(--oneuiux-theme-bordercolor);
  background: var(--oneuiux-theme-text);
  border-radius: 20px;
  padding: 1px;
  display: inline-block;
}

.counter-number>span {
  min-width: 18px;
  display: inline-block;
  text-align: center;
}

.counter-number .btn {
  border: 1px solid var(--oneuiux-theme-bordercolor);
  background-color: var(--oneuiux-theme-text);
  color: var(--oneuiux-theme-color);
}

.counter-number.vertical {
  border: 1px solid var(--oneuiux-theme-bordercolor);
  padding: 0;
}

.counter-number.vertical>span {
  display: block;
}

.counter-number.vertical .btn {
  margin: -1px;
}

.counter-number.vertical .btn i {
  font-size: 17px;
}

@media screen and (max-width: 360px) {
  .btn-44 {
    height: 40px;
    line-height: 38px;
    width: 40px;
  }
}

/* 7. Form elements */
.form-floating {
  position: relative;
}

.form-floating>label {
  padding: var(--oneuiux-padding) 0;
  line-height: 32px;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  font-size: 13px;
  padding: 5px 0 var(--oneuiux-padding) 0;
  line-height: 28px;
  height: auto;
  transform: scale(0.85) translateY(-0.5rem) translateX(0rem);
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
  font-size: 13px;
  padding: 5px 0 var(--oneuiux-padding) 0;
  line-height: 28px;
  height: auto;
  transform: scale(0.85) translateY(-0.5rem) translateX(0rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  font-size: 13px;
  padding: 5px 0 var(--oneuiux-padding) 0;
  line-height: 28px;
  height: auto;
  width: auto;
  transform: scale(0.85) translateY(-0.5rem) translateX(0rem);
}

.form-floating .form-control {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: var(--oneuiux-theme-bordercolor);
  border-radius: 0;
  padding: var(--oneuiux-padding) 0 var(--oneuiux-padding) 0;
  height: auto;
  line-height: 30px;
  -webkit-appearance: none;
}

.form-floating .form-control:not(:-moz-placeholder-shown) {
  padding-top: calc(var(--oneuiux-padding) + 13px);
  padding-bottom: calc(var(--oneuiux-padding) - 3px);
  line-height: 20px;
}

.form-floating .form-control:not(:-ms-input-placeholder) {
  padding-top: calc(var(--oneuiux-padding) + 13px);
  padding-bottom: calc(var(--oneuiux-padding) - 3px);
  line-height: 20px;
}

.form-floating .form-control:focus,
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: calc(var(--oneuiux-padding) + 13px);
  padding-bottom: calc(var(--oneuiux-padding) - 3px);
  line-height: 20px;
}

.form-floating .form-control.is-valid {
  border-color: #21E985;
}

.form-floating .form-control.is-valid:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.form-floating .form-control.is-invalid {
  border-color: #f03d4f;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.form-floating.is-valid:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  background-color: #21E985;
}

.form-floating.is-invalid:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  background-color: #f03d4f;
}

.form-control {
  -webkit-appearance: none;
  border-color: var(--oneuiux-theme-bordercolor);
  border-radius: var(--oneuiux-rounded);
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.trasparent-input {
  font-size: 50px;
  line-height: 68px;
  padding: 10px 15px;
  border: 0;
  background-color: transparent;
  display: block;
  width: 100%;
  border-radius: var(--oneuiux-rounded);
}

.trasparent-input:focus {
  background-color: var(--oneuiux-theme-bordercolor);
  outline: none;
}

.tooltip-btn {
  background: none;
  border: 0;
  position: absolute;
  z-index: 2;
  top: 50%;
  margin-top: -18px;
  right: 0px;
  padding: 5px 10px;
  line-height: 26px;
}

.search-header {
  position: relative;
}

.search-header .form-control {
  padding: 0.625rem 0.75rem;
}

/* range picker slider */
.noUi-target {
  background-color: var(--oneuiux-theme-color-light);
  box-shadow: none;
  -webkit-box-shadow: none;
}

.noUi-target.noUi-horizontal {
  height: 10px;
}

.noUi-target .noUi-origin .noUi-handle {
  height: 30px;
  width: 30px;
  top: -11px;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.noUi-target .noUi-origin .noUi-handle:before {
  display: none;
}

.noUi-target .noUi-origin .noUi-handle:after {
  display: none;
}

.noUi-target .noUi-connect {
  background-color: var(--oneuiux-theme-color);
}

.number-incrementer {
  vertical-align: middle;
}

.number-incrementer .btn {
  padding: 0 10px;
  line-height: 18px;
  margin: 0;
}

.number-incrementer p {
  line-height: 16px;
  display: block;
  font-size: 12px;
}

.form-check.avatar {
  display: block;
  position: relative;
  padding: 0;
}

.form-check.avatar .form-check-input {
  position: absolute;
  left: 3px;
  bottom: 3px;
  margin-left: 0;
  z-index: 1;
  border-radius: 10px;
  opacity: 0.5;
}

.form-check.avatar .form-check-input:checked {
  opacity: 1;
  background-color: var(--oneuiux-theme-color-grad-3);
}

/* 8. loader */
.loader-wrap {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.loader-wrap .loader-cube-wrap {
  width: 100px;
}

.progressstimer {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  margin: 0 auto 10px auto;
  position: relative;
}

.progressstimer img {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.progressstimer .timer {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.circular-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.circular-loader div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.circular-loader div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--oneuiux-theme-color);
  margin: -4px 0 0 -4px;
}

.circular-loader div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
  animation-delay: -0.036s;
}

.circular-loader div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.circular-loader div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
  animation-delay: -0.072s;
}

.circular-loader div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.circular-loader div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
  animation-delay: -0.108s;
}

.circular-loader div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.circular-loader div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
  animation-delay: -0.144s;
}

.circular-loader div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.circular-loader div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.circular-loader div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.circular-loader div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
  animation-delay: -0.216s;
}

.circular-loader div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.circular-loader div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
  animation-delay: -0.252s;
}

.circular-loader div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.circular-loader div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
  animation-delay: -0.288s;
}

.circular-loader div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.circular-loader.white div:after {
  background-color: #ffffff;
}

@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 9. header */
.header {
  padding: var(--oneuiux-padding);
  line-height: 44px;
  width: 42%;
  top: 0;
  /* left: 0; */
  z-index: 8;
  position: relative;
}

.menu-open .header {
  /* min-width: 100%; */
  width: 1320px;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header>* {
  z-index: 1;
  position: relative;
}

.header:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 0 0 var(--oneuiux-rounded) var(--oneuiux-rounded);
  background-color: var(--oneuiux-header-active);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  -webkt-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 0;
  opacity: 0;
}

.header.active,
.header.header-filled {
  color: var(--oneuiux-theme-text-primary) !important;
}

.header.active:after,
.header.header-filled:after {
  opacity: 0.95;
}

.header.active>*,
.header.header-filled>* {
  z-index: 1;
  position: relative;
}

.header p {
  line-height: 20px;
}

.header.shadow-none:after {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.header.border-bottom {
  border: none !important;
}

.header.border-bottom:after {
  border-bottom: 1px solid var(--oneuiux-theme-bordercolor);
}

/* 10. avatar */
.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

.avatar>.icons {
  line-height: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.avatar>i {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.avatar>img {
  width: 100%;
  vertical-align: top;
}

.avatar.avatar-6 {
  line-height: 6px;
  height: 6px;
  width: 6px;
}

.avatar.avatar-10 {
  line-height: 10px;
  height: 10px;
  width: 10px;
}

.avatar.avatar-15 {
  line-height: 15px;
  height: 15px;
  width: 15px;
}

.avatar.avatar-20 {
  line-height: 20px;
  height: 20px;
  width: 20px;
}

.avatar.avatar-30 {
  line-height: 30px;
  height: 30px;
  width: 30px;
}

.avatar.avatar-36 {
  line-height: 36px;
  height: 36px;
  width: 36px;
}

.avatar.avatar-40 {
  line-height: 40px;
  height: 40px;
  width: 40px;
}

.avatar.avatar-44 {
  line-height: 44px;
  height: 44px;
  width: 44px;
}

.avatar.avatar-50 {
  line-height: 50px;
  height: 50px;
  width: 50px;
}

.avatar.avatar-60 {
  line-height: 60px;
  height: 60px;
  width: 60px;
}

.avatar.avatar-70 {
  line-height: 70px;
  height: 70px;
  width: 70px;
}

.avatar.avatar-80 {
  line-height: 80px;
  height: 80px;
  width: 80px;
}

.avatar.avatar-90 {
  line-height: 90px;
  height: 90px;
  width: 90px;
}

.avatar.avatar-100 {
  line-height: 100px;
  height: 100px;
  width: 100px;
}

.avatar.avatar-110 {
  line-height: 110px;
  height: 110px;
  width: 110px;
}

.avatar.avatar-120 {
  line-height: 120px;
  height: 120px;
  width: 120px;
}

.avatar.avatar-130 {
  line-height: 130px;
  height: 130px;
  width: 130px;
}

.avatar.avatar-140 {
  line-height: 140px;
  height: 140px;
  width: 140px;
}

.avatar.avatar-150 {
  line-height: 150px;
  height: 150px;
  width: 150px;
}

.avatar.avatar-160 {
  line-height: 160px;
  height: 160px;
  width: 160px;
}

.avatar.avatar-250 {
  line-height: 250px;
  height: 250px;
  width: 250px;
}

.avatar.avatar-300 {
  line-height: 300px;
  height: 300px;
  width: 300px;
}

.avatar-group {
  z-index: 1;
  display: flex;
}

.avatar-group .avatar {
  z-index: 1;
  margin-left: -20px;
}

.avatar-group .avatar:nth-child(1) {
  z-index: 6;
}

.avatar-group .avatar:nth-child(2) {
  z-index: 5;
}

.avatar-group .avatar:nth-child(3) {
  z-index: 4;
}

.avatar-group .avatar:nth-child(4) {
  z-index: 3;
}

.avatar-group .avatar:nth-child(5) {
  z-index: 2;
}

.avatar-group .avatar:nth-child(6) {
  z-index: 1;
}

.avatar-group .avatar:nth-child(7) {
  z-index: 0;
}

.avatar-group .avatar:nth-child(1) {
  margin-left: 0;
}

/* 11. sidebar */
.sidebar-wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;
  opacity: 0;
  left: -100%;
  padding: var(--oneuiux-padding);
}

.sidebar-wrap .sidebar {
  float: left;
  display: block;
  min-height: 100%;
  padding: var(--oneuiux-padding);
  position: relative;
  width: 280px;
  z-index: 1;
  border-radius: var(--oneuiux-rounded);
  color: var(--oneuiux-theme-text);
}

.sidebar-wrap .sidebar .profile-sidebar {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-wrap .sidebar .nav {
  flex-direction: column;
}

.sidebar-wrap .sidebar .nav .nav-item {
  margin-bottom: 10px;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle {
  color: var(--oneuiux-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow>.plus {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow>.minus {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show {
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded) 0 0;
  background-color: rgba(255, 255, 255, 0.15);
  color: var(--oneuiux-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow>.plus {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow>.minus {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.15);
  border: 0;
  padding: 5px;
  border-radius: 0 0 var(--oneuiux-rounded) var(--oneuiux-rounded);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu>li {
  margin-bottom: 5px;
  color: var(--oneuiux-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu>li:last-child {
  margin-bottom: 0;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link {
  display: flex;
  line-height: 40px;
  font-size: 16px;
  padding: 5px;
  border-radius: var(--oneuiux-rounded);
  color: var(--oneuiux-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link>* {
  line-height: 40px;
  vertical-align: middle;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link>.arrow {
  opacity: 0.3;
  margin-right: 15px;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link>.icon {
  margin-right: 15px;
  border-radius: calc(var(--oneuiux-rounded) - 5px);
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active>.icon,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover>.icon {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active>.arrow,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover>.arrow {
  opacity: 0.5;
}

.sidebar-wrap.sidebar-overlay::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--oneuiux-theme-color);
  opacity: 0;
}

.sidebar-wrap.sidebar-overlay .sidebar {
  width: 280px;
  left: -280px;
  background-color: var(--oneuiux-theme-color);
  z-index: 1;
}

.sidebar-wrap.sidebar-overlay .sidebar .nav .nav-item.dropdown .dropdown-menu {
  position: relative !important;
  transform: none !important;
}

.sidebar-wrap.sidebar-pushcontent {
  background-color: transparent;
  width: 280px;
  left: -280px;
}

.sidebar-wrap.sidebar-pushcontent+main {
  margin-left: 0;
  width: 100%;
}

.sidebar-wrap.sidebar-pushcontent .sidebar {
  background-color: var(--oneuiux-theme-color);
  width: 100%;
  left: 0;
}

.sidebar-wrap.sidebar-pushcontent .sidebar .nav .nav-item.dropdown .dropdown-menu {
  position: relative !important;
  transform: none !important;
}

.sidebar-wrap.sidebar-pushcontent .closemenu {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu {
  background: var(--oneuiux-theme-color);
}

.sidebar-wrap.sidebar-fullmenu .closemenu {
  position: fixed;
  background: transparent;
  transform: none;
  bottom: 0;
  right: 0;
  margin: 0px auto;
  color: #fff !important;
  z-index: 2;
  height: 66px;
  width: 66px;
  border-left: 33px solid transparent;
  border-bottom: 33px solid rgba(0, 0, 0, 0.2);
  border-right: 33px solid rgba(0, 0, 0, 0.1);
  border-top: 33px solid transparent;
  opacity: 1;
}

.sidebar-wrap.sidebar-fullmenu .closemenu:after {
  content: "x";
  position: absolute;
  bottom: -20px;
  right: -20px;
  font-size: 14px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  background: #000;
  text-align: center;
  display: inline-block;
  letter-spacing: 0;
  border-radius: 15px;
  text-indent: 0;
}

.sidebar-wrap.sidebar-fullmenu .sidebar {
  width: 100%;
  left: 0;
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .profile-sidebar {
  position: relative;
  max-width: 80%;
  margin: 0 auto 30px auto;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav {
  width: 80%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item {
  width: 32%;
  margin-right: 2%;
  text-align: center;
  line-height: 30px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item:nth-child(3n) {
  margin-right: 0;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link {
  flex-direction: column;
  font-size: 12px;
  padding-top: 10px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link>* {
  line-height: 30px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link .icon {
  margin: 0 auto;
  font-size: 18px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link .arrow {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link.active .arrow {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow {
  display: block;
  position: absolute;
  margin: 5px;
  line-height: 25px;
  width: 10px;
  text-align: center;
  right: 0;
  bottom: 0;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow .plus {
  display: block;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow .minus {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow .plus {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow .minus {
  display: block;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu {
  background-color: var(--oneuiux-theme-color);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: -2px !important;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu li {
  width: 100%;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu li .nav-link {
  flex-direction: row;
  font-size: 14px;
  padding-top: 0;
  line-height: 40px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu li .nav-link .icon {
  margin: 0 5px 0 auto;
  font-size: 16px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.sidebar-wrap .closemenu {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0;
  text-transform: uppercase;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  z-index: 1;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  border-radius: 0;
  color: #fff !important;
  text-indent: 100%;
  width: 100%;
  height: 100%;
}

.menu-open {
  overflow: hidden;
}

.menu-open .sidebar-wrap {
  left: 0;
  opacity: 1;
}

.menu-open .sidebar-wrap.sidebar-overlay::after {
  opacity: 0.2;
}

.menu-open .sidebar-wrap.sidebar-overlay .sidebar {
  left: 0;
}

.menu-open .sidebar-wrap.sidebar-pushcontent+main {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  margin-left: 280px;
  position: relative;
  z-index: 99;
}

.menu-open.transform-page-scale .sidebar-pushcontent+main {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.page-sidebar {
  width: 280px;
}

/* 12. card */
.card {
  background-color: var(--oneuiux-card-color);
  color: var(--oneuiux-card-text);
  border-radius: var(--oneuiux-rounded);
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.card.max-height {
  height: calc(100% - 12px);
  display: block !important;
}

.card .card-header {
  background-color: transparent;
  border-color: var(--oneuiux-theme-bordercolor);
  padding: var(--oneuiux-padding);
  border-top-left-radius: var(--oneuiux-rounded);
  border-top-right-radius: var(--oneuiux-rounded);
}

.card .card-body {
  padding: var(--oneuiux-padding);
}

.card .card-footer {
  background-color: transparent;
  border-color: var(--oneuiux-theme-bordercolor);
  padding: var(--oneuiux-padding);
  border-bottom-left-radius: var(--oneuiux-rounded);
  border-bottom-right-radius: var(--oneuiux-rounded);
}

.card.theme-bg,
.card.dark-bg,
.card.bg-danger,
.card.bg-success,
.card.bg-primary,
.card.bg-warning,
.card.bg-info,
.card.bg-dark,
.card.theme-radial-gradient,
.card.circle,
.card.bg-opac {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.18);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.18);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.18);
  background-color: var(--oneuiux-theme-color);
  color: var(--oneuiux-theme-text);
}

.card.selected {
  border: 2px solid #21E985;
}

.card>.card.bg-opac {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

a.card {
  text-decoration: none;
}

.toast {
  border-radius: var(--oneuiux-rounded);
}

.toast .toast-header {
  padding: calc(var(--oneuiux-padding) - 4px) var(--oneuiux-padding);
  border-top-left-radius: var(--oneuiux-rounded);
  border-top-right-radius: var(--oneuiux-rounded);
}

.toast .toast-body {
  padding: var(--oneuiux-padding);
}

.toast .toast-footer {
  padding: calc(var(--oneuiux-padding) - 4px) var(--oneuiux-padding);
  border-bottom-left-radius: var(--oneuiux-rounded);
  border-bottom-right-radius: var(--oneuiux-rounded);
}

.modal .modal-dialog .modal-content {
  border-radius: var(--oneuiux-rounded);
  border: 0;
}

.modal .modal-dialog.modal-xsm {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.accordion {
  border-radius: var(--oneuiux-rounded);
}

.accordion .accordion-item:first-child {
  border-radius: var(--oneuiux-rounded);
}

.accordion .accordion-item:first-child .accordion-header {
  border-radius: var(--oneuiux-rounded);
}

.accordion .accordion-item:first-child .accordion-header .accordion-button {
  border-radius: var(--oneuiux-rounded);
}

.accordion .accordion-item:last-child {
  border-radius: var(--oneuiux-rounded);
}

.accordion .accordion-item:last-child .accordion-header {
  border-radius: var(--oneuiux-rounded);
}

.accordion .accordion-item:last-child .accordion-header .accordion-button.collapsed {
  border-radius: var(--oneuiux-rounded);
}

@media screen and (max-width: 375px) {
  .toast {
    width: 300px;
  }
}

/* 13. swiper sliders */
.swiper-container {
  z-index: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: var(--oneuiux-theme-color);
}

.swiper-pagination.white .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.6;
}

.swiper-pagination.white .swiper-pagination-bullet-active {
  background-color: var(--oneuiux-theme-color);
  opacity: 1;
}

.pagination-smallline .swiper-pagination-bullet {
  height: 4px;
  width: 10px;
  border-radius: 2px;
}

.pagination-rightnumber {
  position: absolute;
  top: 45%;
  right: 15px;
  left: auto !important;
  width: auto;
  text-align: right;
}

.pagination-rightnumber .swiper-pagination-bullet {
  background: none !important;
  color: var(--oneuiux-theme-text-secondary);
  height: 30px;
  line-height: 30px;
  position: relative;
  display: block;
  width: 100%;
  opacity: 0.5;
}

.pagination-rightnumber .swiper-pagination-bullet::after {
  content: "";
  height: 2px;
  width: 15px;
  border-radius: 2px;
  background-color: var(--oneuiux-theme-text-secondary);
  display: block;
  position: absolute;
  right: 15px;
  top: 49%;
}

.pagination-rightnumber .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--oneuiux-theme-color);
  opacity: 1;
}

.pagination-rightnumber .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  width: 30px;
  background-color: var(--oneuiux-theme-color);
}

.introswiper {
  height: 100%;
}

.introswiper .swiper-wrapper .swiper-slide {
  padding-left: var(--oneuiux-padding);
  padding-right: var(--oneuiux-padding);
  overflow: hidden;
}

.introswiperbottom {
  height: auto;
  margin-top: -300px;
}

.introswiperbottom .swiper-wrapper .swiper-slide {
  padding: 0px var(--oneuiux-padding) 65px var(--oneuiux-padding);
  overflow: hidden;
}

.introswiperbottom .swiper-wrapper .swiper-slide .imgtop {
  height: 340px;
  margin: 0 auto;
  position: relative;
  max-width: 320px;
  width: 100%;
}

.cardswiper .swiper-wrapper .swiper-slide {
  width: 280px;
  padding: 0 5px 10px 15px;
}

.cardswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--oneuiux-padding);
}

.cardswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--oneuiux-padding);
}

.cardswiper .swiper-wrapper .swiper-slide .card {
  min-height: 150px;
}

.scrollx .swiper-wrapper .swiper-slide {
  width: auto;
  padding: 0 10px 0px 0px;
}

.scrollx .swiper-wrapper .swiper-slide .number {
  padding: 12px;
}

.connectionwiper .swiper-wrapper .swiper-slide {
  width: auto;
  padding: 0 5px 10px 15px;
}

.connectionwiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--oneuiux-padding);
}

.connectionwiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--oneuiux-padding);
}

.blogswiper .swiper-wrapper .swiper-slide {
  width: auto;
  padding: 0;
}

.summayswiper .swiper-wrapper .swiper-slide {
  width: 200px;
  padding: 0 5px 10px 15px;
}

.summayswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--oneuiux-padding);
}

.summayswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--oneuiux-padding);
}

.tagsswiper .swiper-wrapper .swiper-slide {
  width: auto;
  padding: 0 5px 10px 5px;
}

.tagsswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--oneuiux-padding);
}

.tagsswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--oneuiux-padding);
}

@media screen and (max-width: 375px) {
  .introswiper .slidebg {
    max-height: 150px;
  }

  .introswiper .swiper-slide .slideimg {
    max-height: 200px;
  }

  .introswiper .swiper-slide-two .slideimg {
    max-height: 120px;
  }

  .introswiper .swiper-slide-three .slideimg {
    max-height: 70px;
  }

  .introswiper .swiper-slide-three .slideimg2 {
    max-height: 180px;
  }
}

@media screen and (max-height: 800px) {
  .introswiperbottom {
    height: auto;
    margin-top: -170px;
  }

  .introswiperbottom .swiper-wrapper .swiper-slide {
    padding: 0px var(--oneuiux-padding) 45px var(--oneuiux-padding);
  }

  .introswiperbottom .swiper-wrapper .swiper-slide .imgtop {
    height: 200px;
  }
}

/* 14. list */
.list-group .list-group-item {
  padding: var(--oneuiux-padding);
}

.list-group.bg-none .list-group-item {
  background-color: transparent;
  border-color: var(--oneuiux-theme-bordercolor);
}

/* chat list */
.chat-list {
  margin: 0px auto 0px auto;
  font-size: 15px;
}

.chat-list .figure,
.chat-list figure,
.chat-list iframe,
.chat-list video {
  border-radius: calc(var(--oneuiux-rounded) - 5px);
  overflow: hidden;
  display: block;
  max-width: 100%;
}

.chat-list .left-chat {
  margin-bottom: 15px;
}

.chat-list .left-chat .chat-block {
  background-color: var(--oneuiux-theme-text);
  padding: 15px;
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded) var(--oneuiux-rounded) 0px;
  margin-right: 3px;
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 90%;
}

.chat-list .left-chat .chat-block:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -16px;
  height: 8px;
  width: 8px;
  border-radius: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  z-index: 1;
  border-top-color: var(--oneuiux-theme-text);
  border-left-color: var(--oneuiux-theme-text);
}

.chat-list .left-chat .time {
  padding-left: 15px;
}

.chat-list .right-chat {
  margin-bottom: 15px;
  text-align: right;
}

.chat-list .right-chat .chat-block {
  text-align: left;
  background-color: var(--oneuiux-theme-color);
  color: var(--oneuiux-theme-text);
  padding: 15px;
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded) 0px var(--oneuiux-rounded);
  margin-left: 3px;
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 90%;
}

.chat-list .right-chat .chat-block:before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -16px;
  height: 8px;
  width: 8px;
  border-radius: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  border-right-color: var(--oneuiux-theme-color);
  border-top-color: var(--oneuiux-theme-color);
  z-index: 0;
}

.chat-list .right-chat .time {
  padding-right: 15px;
}

.log-information li {
  border: 0;
  padding: 0px 15px 15px 40px !important;
  background-color: transparent;
}

.log-information li:after {
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(121, 121, 121, 0.2);
  display: block;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 0;
}

.log-information li:before {
  content: "";
  height: 1px;
  width: 20px;
  background: rgba(121, 121, 121, 0.2);
  display: block;
  position: absolute;
  left: 20px;
  top: 22px;
  z-index: 0;
}

.log-information li .avatar {
  position: absolute;
  left: 13px;
  top: 15px;
  border-width: 3px;
  border-style: solid;
  z-index: 1;
  background-color: var(--oneuiux-theme-text);
}

.log-information li .avatar:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.log-information li p {
  margin: 0;
}

.log-information.bubble-sheet li p {
  padding: 10px 15px;
  position: relative;
  background-color: var(--oneuiux-card-color);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: calc(var(--oneuiux-rounded) - 5px);
  opacity: 1;
}

.log-information.bubble-sheet li p:before {
  content: "";
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  height: 10px;
  width: 10px;
  border-radius: 0px;
  position: absolute;
  left: -20px;
  top: 10px;
}

/* 15. footer */
.footer {
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: 42%;
  padding-bottom: 5px;
  z-index: 9;
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded) 0 0;
  background-color: var(--oneuiux-footer);
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  -webkt-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
}

.footer .nav {
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
}

.footer .nav .nav-item {
  height: 60px;
}

.footer .nav .nav-item .nav-link {
  text-align: center;
  background: transparent;
  align-self: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  height: 100%;
  line-height: 44px;
  color: var(--oneuiux-theme-text-secondary);
  padding: calc(var(--oneuiux-padding) - 5px) calc(var(--oneuiux-padding) - 10px);
}

.footer .nav .nav-item .nav-link span {
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.footer .nav .nav-item .nav-link span .nav-icon {
  font-size: 17px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 auto 5px auto;
}

.footer .nav .nav-item .nav-link span .nav-text {
  font-size: 10px;
  line-height: 15px;
  display: block;
}

.footer .nav .nav-item .nav-link.active {
  color: var(--oneuiux-theme-color);
}

.footer .nav .nav-item.centerbutton {
  padding: 10px;
  transition: none;
}

.footer .nav .nav-item.centerbutton .nav-link {
  position: relative;
  padding: 8px;
  height: 76px;
  width: 76px;
  margin: 0 auto;
  margin-top: -30px;
  transition: none;
}

.footer .nav .nav-item.centerbutton .nav-link>span {
  height: 60px;
  line-height: 56px;
  width: 60px;
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: 0px auto 0 auto;
  color: var(--oneuiux-theme-text);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -webkt-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.footer .nav .nav-item.centerbutton .nav-link>span i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  display: inline-block;
  width: 30px;
}

.footer .nav .nav-item.centerbutton.rotate>.active>span i {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.footer .nav .nav-item.squarebtn {
  padding: 10px;
  transition: none;
}

.footer .nav .nav-item.squarebtn .nav-link {
  position: relative;
  padding: 8px;
  height: 76px;
  width: 76px;
  margin: 0 auto;
  margin-top: -30px;
  transition: none;
}

.footer .nav .nav-item.squarebtn .nav-link>span {
  height: 60px;
  line-height: 56px;
  width: 60px;
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded);
  margin: 0px auto 0 auto;
  color: var(--oneuiux-theme-text);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -webkt-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.footer .nav .nav-item.squarebtn .nav-link>span i {
  text-align: center;
  display: inline-block;
  width: 30px;
  margin-right: -4px;
}

.menu-open .footer {
  margin-bottom: -85px;
}

.footer-info {
  padding: 0px;
  line-height: 30px;
}

.chat-post {
  width: 100%;
  background-color: var(--oneuiux-card-color);
  padding: calc(var(--oneuiux-padding) - 5px) var(--oneuiux-padding);
  border-radius: var(--oneuiux-rounded) var(--oneuiux-rounded) 0 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
  z-index: 2;
}

/* 16. footer */
.dark-mode {
  /* color schemes */
  --oneuiux-theme-text: #ffffff;
  --oneuiux-theme-text-primary: rgba(255, 255, 255, 0.92);
  --oneuiux-theme-text-secondary: rgba(255, 255, 255, 0.8);
  --oneuiux-theme-text-secondary-light: #bbbbbb;
  --oneuiux-theme-bordercolor: rgba(255, 255, 255, 0.125);
  --oneuiux-header: transparent;
  --oneuiux-sidebar: var(--oneuiux-theme-color);
  --oneuiux-page-text: #ffffff;
  --oneuiux-page-link: var(--oneuiux-theme-color);
  --oneuiux-theme-color: #0d6efd;
  --oneuiux-theme-link: #438cfa;
  --oneuiux-header-active: #044fc0;
  --oneuiux-footer: #044fc0;
  --oneuiux-card-color: #0036aa;
  --oneuiux-page-color: rgba(0, 0, 0, 0.70);
  --oneuiux-page-bg-1: #0a57ca;
  --oneuiux-page-bg-2: #073881;
  --oneuiux-page-bg-3: #031838;
}

.dark-mode .theme-indigo {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #8b9dff;
  --oneuiux-theme-color-grad-2: #5c00f1;
  --oneuiux-theme-color-grad-3: #68008e;
  /* color schemes */
  --oneuiux-theme-color: #6610f2;
  --oneuiux-theme-link: #a167ff;
  --oneuiux-header-active: #2d066d;
  --oneuiux-footer: #2d066d;
  --oneuiux-card-color: #340d77;
  --oneuiux-page-bg-1: #19033d;
  --oneuiux-page-bg-2: #11022a;
  --oneuiux-page-bg-3: #010003;
}

.dark-mode .theme-purple {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #e908d8;
  --oneuiux-theme-color-grad-2: #5a2ab3;
  --oneuiux-theme-color-grad-3: #300284;
  /* color schemes */
  --oneuiux-theme-color: #6f42c1;
  --oneuiux-theme-link: #a883ee;
  --oneuiux-header-active: #321d57;
  --oneuiux-footer: #321d57;
  --oneuiux-card-color: #402770;
  --oneuiux-page-bg-1: #1c1031;
  --oneuiux-page-bg-2: #130b22;
  --oneuiux-page-bg-3: #020103;
}

.dark-mode .theme-pink {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ff71c3;
  --oneuiux-theme-color-grad-2: #e80075;
  --oneuiux-theme-color-grad-3: #4d00da;
  /* color schemes */
  --oneuiux-theme-color: #E50A8E;
  --oneuiux-theme-link: #fd46b4;
  --oneuiux-header-active: #680540;
  --oneuiux-footer: #680540;
  --oneuiux-card-color: #5c0137;
  --oneuiux-page-bg-1: #2b021b;
  --oneuiux-page-bg-2: #18010f;
  --oneuiux-page-bg-3: black;
}

.dark-mode .theme-red {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffc699;
  --oneuiux-theme-color-grad-2: #ff5d83;
  --oneuiux-theme-color-grad-3: #dc006a;
  /* color schemes */
  --oneuiux-theme-color: #f03d4f;
  --oneuiux-theme-link: #f46c7a;
  --oneuiux-header-active: #960e1c;
  --oneuiux-footer: #960e1c;
  --oneuiux-card-color: #810816;
  --oneuiux-page-bg-1: #640811;
  --oneuiux-page-bg-2: #51060e;
  --oneuiux-page-bg-3: #2b0307;
}

.dark-mode .theme-orange {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffda6b;
  --oneuiux-theme-color-grad-2: #ff7c15;
  --oneuiux-theme-color-grad-3: #e80774;
  /* color schemes */
  --oneuiux-theme-color: #fd7e14;
  --oneuiux-theme-link: #fd9a47;
  --oneuiux-header-active: #813b01;
  --oneuiux-footer: #813b01;
  --oneuiux-card-color: #682f00;
  --oneuiux-page-bg-1: #592901;
  --oneuiux-page-bg-2: #3a1b00;
  --oneuiux-page-bg-3: #120800;
}

.dark-mode .theme-yellow {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffdb6f;
  --oneuiux-theme-color-grad-2: #f0ac00;
  --oneuiux-theme-color-grad-3: #f03000;
  /* color schemes */
  --oneuiux-theme-color: #ffc107;
  --oneuiux-theme-link: #ffce3a;
  --oneuiux-header-active: #775900;
  --oneuiux-footer: #775900;
  --oneuiux-card-color: #684f00;
  --oneuiux-page-bg-1: #443300;
  --oneuiux-page-bg-2: #302400;
  --oneuiux-page-bg-3: #070500;
}

.dark-mode .theme-green {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #bdffaa;
  --oneuiux-theme-color-grad-2: #04c5de;
  --oneuiux-theme-color-grad-3: #00a0f5;
  /* color schemes */
  --oneuiux-theme-color: #12da76;
  --oneuiux-theme-link: #4fee9f;
  --oneuiux-header-active: #0b703e;
  --oneuiux-footer: #0b703e;
  --oneuiux-card-color: #076637;
  --oneuiux-page-bg-1: #074224;
  --oneuiux-page-bg-2: #052f1a;
  --oneuiux-page-bg-3: #010a06;
}

.dark-mode .theme-teal {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #a2fffa;
  --oneuiux-theme-color-grad-2: #00a2ad;
  --oneuiux-theme-color-grad-3: #3f51b5;
  /* color schemes */
  --oneuiux-theme-color: #0ebbac;
  --oneuiux-theme-link: #3ce0af;
  --oneuiux-header-active: #045e56;
  --oneuiux-footer: #045e56;
  --oneuiux-card-color: #044b45;
  --oneuiux-page-bg-1: #052219;
  --oneuiux-page-bg-2: #03100c;
  --oneuiux-page-bg-3: black;
}

.dark-mode .theme-cyan {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #74e5fb;
  --oneuiux-theme-color-grad-2: #258cd6;
  --oneuiux-theme-color-grad-3: #3d00ab;
  /* color schemes */
  --oneuiux-theme-color: #00C2FF;
  --oneuiux-theme-link: #46d2fd;
  --oneuiux-header-active: #045f7a;
  --oneuiux-footer: #045f7a;
  --oneuiux-card-color: #015169;
  --oneuiux-page-bg-1: #002f3d;
  --oneuiux-page-bg-2: #001f29;
  --oneuiux-page-bg-3: black;
}

.dark-mode .theme-gold {
  /* radial gradient colors */
  --oneuiux-theme-color-grad-1: #ffd700;
  --oneuiux-theme-color-grad-2: #d4a306;
  --oneuiux-theme-color-grad-3: #b58c00;
  /* color schemes */
  --oneuiux-theme-color: #f4c91b;
  --oneuiux-theme-link: #ffd83d;
  --oneuiux-header-active: #e5b613;
  --oneuiux-footer: #e5b613;
  --oneuiux-card-color: #ad7e00;
  --oneuiux-page-bg-1: #443300;
  --oneuiux-page-bg-2: #302400;
  --oneuiux-page-bg-3: #070500;
}

.dark-mode .alert-danger,
.dark-mode .card.alert-danger,
.dark-mode .bg-danger-light {
  background-color: rgba(240, 61, 79, 0.35);
}

.dark-mode .alert-info,
.dark-mode .card.alert-info,
.dark-mode .bg-info-light {
  background-color: rgba(32, 201, 151, 0.35);
}

.dark-mode .alert-primary,
.dark-mode .card.alert-primary,
.dark-mode .bg-primary-light {
  background-color: rgba(13, 110, 253, 0.35);
}

.dark-mode .alert-warning,
.dark-mode .card.alert-warning,
.dark-mode .bg-warning-light {
  background-color: rgba(255, 193, 7, 0.35);
}

.dark-mode .alert-success,
.dark-mode .card.alert-success,
.dark-mode .bg-success-light {
  background-color: rgba(33, 233, 133, 0.35);
}

.dark-mode .alert-theme,
.dark-mode .card.alert-theme,
.dark-mode .bg-theme-light {
  background-color: var(--oneuiux-theme-color-light);
}

.dark-mode main.bg-1 {
  background: url("../img/bg1-dark.png") fixed;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
}

.dark-mode main.bg-2 {
  background: url("../img/bg2-dark.png") fixed;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center right;
}

.dark-mode main.bg-3 {
  background: url("../img/bg3-dark.png") fixed;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: 35% bottom;
}

.dark-mode body .bg-theme main {
  background-color: rgba(0, 0, 0, 0.75);
}

.dark-mode .progressbar-text {
  color: var(--oneuiux-theme-text) !important;
}

.dark-mode .border,
.dark-mode .border-top,
.dark-mode .border-end,
.dark-mode .border-bottom,
.dark-mode .border-start,
.dark-mode .noUi-target {
  border-color: var(--oneuiux-theme-bordercolor) !important;
}

.dark-mode body,
.dark-mode .btn-outline-dark {
  color: var(--oneuiux-theme-text);
}

.dark-mode a:not(.btn):not(.list-group-item) {
  color: var(--oneuiux-theme-link);
}

.dark-mode a:not(.btn):not(.list-group-item):focus,
.dark-mode a:not(.btn):not(.list-group-item):hover,
.dark-mode a:not(.btn):not(.list-group-item):active {
  color: #ffffff;
}

.dark-mode .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--oneuiux-theme-text);
}

.dark-mode .tabs .nav-item>.nav-link:not(.active) {
  --oneuiux-theme-color: var(--oneuiux-theme-text);
}

.dark-mode .chartdoughnut>.datadisplay {
  --oneuiux-theme-text: var(--oneuiux-theme-color);
}

.dark-mode .btn-light {
  background-color: var(--oneuiux-card-color);
  color: var(--oneuiux-theme-text);
  border-color: var(--oneuiux-page-bg-1);
}

.dark-mode .bg-light,
.dark-mode .counter-number,
.dark-mode .counter-number .btn {
  background-color: var(--oneuiux-card-color) !important;
}

.dark-mode .btn-default {
  color: var(--oneuiux-theme-text);
}

.dark-mode .btn-default:disabled {
  color: var(--oneuiux-theme-text);
}

.dark-mode .card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.1);
}

.dark-mode .accordion-item {
  background-color: var(--oneuiux-card-color);
  border-color: var(--oneuiux-card-color);
}

.dark-mode .accordion-item .accordion-header .accordion-button {
  background-color: var(--oneuiux-card-color);
  color: var(--oneuiux-theme-text);
}

.dark-mode .accordion-item .accordion-collapse.show {
  background-color: var(--oneuiux-card-color);
}

.dark-mode .list-group-item {
  color: var(--oneuiux-theme-text);
}

.dark-mode .bg-white,
.dark-mode .bg-opac-50 {
  background-color: var(--oneuiux-page-color) !important;
}

.dark-mode .log-information.bubble-sheet li p {
  background-color: var(--oneuiux-header-active) !important;
}

.dark-mode .toast {
  background-color: var(--oneuiux-card-color);
}

.dark-mode .toast .toast-header {
  background-color: var(--oneuiux-theme-color);
  color: var(--oneuiux-card-text);
}

.dark-mode .modal-content {
  background-color: var(--oneuiux-theme-color);
}

.dark-mode .modal-content .modal-header {
  border-color: var(--oneuiux-theme-bordercolor);
}

.dark-mode .form-control,
.dark-mode input,
.dark-mode .dark-bg .form-control,
.dark-mode .dark-bg label {
  color: var(--oneuiux-input-text);
}

.dark-mode .form-control {
  background-color: var(--oneuiux-input-color);
  border-color: var(--oneuiux-input-bordercolor);
}

.dark-mode .chat-list .left-chat .chat-block {
  --oneuiux-theme-text: var(--oneuiux-card-color);
}

.dark-mode .daterange-center.daterangepicker {
  background-color: var(--oneuiux-page-bg-2);
}

.dark-mode .daterange-center.daterangepicker .calendar-table .next span,
.dark-mode .daterange-center.daterangepicker .calendar-table .prev span {
  border-color: var(--oneuiux-theme-text);
}

.dark-mode .daterange-center.daterangepicker td.off {
  background-color: var(--oneuiux-card-color) !important;
}

.dark-mode .daterange-center.daterangepicker td.in-range {
  color: var(--oneuiux-theme-text);
}

.dark-mode .text-purple,
.dark-mode .text-color-theme,
.dark-mode .tag {
  color: var(--oneuiux-theme-text);
}

.dark-mode .footer .nav .nav-item:not(.squarebtn) .nav-link.active {
  color: var(--oneuiux-theme-text);
  opacity: 1;
}

/* 17. Filter */
.filter {
  position: fixed;
  z-index: 999;
  width: 280px;
  right: -300px;
  top: 0;
  height: 100%;
  overflow-y: auto;
  padding: var(--oneuiux-padding);
}

.filter-open .filter {
  right: 0;
}

/* 18. Calendar */
.fc {
  border-radius: 5px;
  overflow: hidden;
}

.fc a {
  text-decoration: none;
}

.fc .fc-toolbar-title {
  font-size: 16px;
}

.fc .fc-button {
  padding: 0.275em 0.475em;
  font-size: 0.95em;
  line-height: 1.7;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
  padding: 10px 15px;
}

.fc .fc-toolbar.fc-header-toolbar .fc-button:focus {
  box-shadow: none;
  outline: none;
}

.fc.fc-theme-standard table {
  border: 0 none;
  border-collapse: collapse;
}

.fc.fc-theme-standard table th {
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0;
  line-height: 20px;
  border: none;
}

.fc.fc-theme-standard table th a.fc-col-header-cell-cushion {
  font-weight: normal;
}

.fc.fc-theme-standard table td {
  border: none;
}

.fc.fc-theme-standard table table td {
  background-color: transparent;
  color: inherit;
  font-size: 14px;
  text-decoration: none;
  padding: 0;
  border: 1px solid rgba(190, 190, 190, 0.3);
}

.fc.fc-theme-standard table table td a {
  color: inherit;
  font-weight: normal;
}

.fc.fc-theme-standard table table td .fc-daygrid-more-link {
  font-size: 10px;
}

.fc.fc-theme-standard table table td .fc-daygrid-day-events {
  margin-top: -8px;
}

.fc.fc-theme-standard table table td:first-child {
  border-left: none;
}

.fc.fc-theme-standard table table td:last-child {
  border-right: none;
}

.fc.fc-theme-standard table table tr:first-child td {
  border-bottom: none;
}

.fc.fc-theme-standard table table tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 5px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }
}

/* date range picekr */
.calendar-daterange {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}

.daterange-center.daterangepicker {
  margin: 0 auto !important;
  right: 0 !important;
  left: 0 !important;
  top: 84px !important;
  width: 250px;
  border-radius: 10px;
  border: 0;
  background-color: var(--oneuiux-card-color);
}

.daterange-center.daterangepicker:before,
.daterange-center.daterangepicker:after {
  display: none;
}

.daterange-center.daterangepicker .drp-buttons .drp-selected {
  display: none;
}

.daterange-center.daterangepicker .drp-buttons .cancelBtn {
  background: transparent;
  font-size: 14px;
}

.daterange-center.daterangepicker .drp-buttons .cancelBtn {
  color: var(--oneuiux-theme-color-light);
  background: transparent;
  font-size: 14px;
  font-weight: normal;
}

.daterange-center.daterangepicker .drp-buttons .applyBtn {
  background-color: var(--oneuiux-theme-color);
  color: var(--oneuiux-theme-text);
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 15px;
  font-weight: normal;
}

.daterange-center.daterangepicker .drp-calendar.left,
.daterange-center.daterangepicker .drp-calendar.right {
  padding: 10px;
  background: transparent;
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table {
  border: 0;
  background: transparent;
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td {
  line-height: 28px;
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off.start-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off.end-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off.start-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off.end-date {
  background-color: var(--oneuiux-theme-text);
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.in-range,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off.in-range,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.in-range,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off.in-range {
  background-color: var(--oneuiux-theme-bordercolor);
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.active,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.active:hover,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.in-range.end-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.active,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.active:hover,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.in-range.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.active,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.active:hover,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.in-range.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.active,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.active:hover,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.in-range.end-date {
  background-color: var(--oneuiux-theme-color);
}

/* 19. Logo */
.logo-splash {
  width: auto;
  display: block;
  margin: 0 auto;
}

.logo-splash img {
  width: 100px;
  margin-bottom: 15px;
  border-radius: 50px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.logo-small {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  line-height: 44px;
}

.logo-small img {
  width: 40px;
  margin: 0;
  border-radius: calc(var(--oneuiux-rounded) - 8px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  margin-right: 8px;
  margin-left: 8px;
  border: 0;
  vertical-align: middle;
}

.logo-mid {
  position: fixed;
  margin-bottom: -30px;
  top: 50px;

}

.logo-mid img {
  border-radius: calc(var(--oneuiux-rounded) - 8px);
  border: solid 3px #eee;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff !important;

}

.logo-mid-scrolled {
  position: fixed;
  margin-bottom: -30px;
  /* top: 50px; */
}

.logo-mid-scrolled img {
  border-radius: calc(var(--oneuiux-rounded) - 8px);
  border: solid 3px #eee;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}


.logo-small h5 {
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
}

.logo-img {
  width: 100px;
}

/* 20. tabs layout */
.tabs {
  background-color: var(--oneuiux-theme-bordercolor);
  border-radius: var(--oneuiux-rounded);
  padding: 5px;
}

.tabs .nav-item>.nav-link {
  color: var(--oneuiux-theme-color);
  padding: calc(var(--oneuiux-padding) - 5px) var(--oneuiux-padding);
  line-height: 30px;
  border-radius: calc(var(--oneuiux-rounded) - 5px);
  text-transform: uppercase;
}

.tabs .nav-item>.nav-link.active {
  color: var(--oneuiux-theme-text);
  background-color: var(--oneuiux-theme-color);
}

/* 21. tags */
/* tags */
.tag {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 var(--oneuiux-padding);
  background-color: var(--oneuiux-card-color);
  color: var(--oneuiux-theme-text-secondary-light);
  border-radius: var(--oneuiux-rounded);
  display: inline-block;
}

.tag>i {
  margin-right: 5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}

.tag.active {
  color: var(--oneuiux-theme-text-primary);
  border-color: var(--oneuiux-theme-text-primary) !important;
}

.tag.tag-small {
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 calc(var(--oneuiux-padding) - 5px);
}

.tag.tag-large {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.taglabel {
  width: auto;
  padding: 5px calc(var(--oneuiux-padding) - 5px);
  line-height: 16px;
  background-color: #ffc107;
  z-index: 2;
}

.taglabel:after {
  content: "";
  height: 0px;
  width: 0px;
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #ffc107;
  position: absolute;
  left: -13px;
  top: 0;
}

.taglabel:before {
  content: "";
  height: 0px;
  width: 0px;
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
  border-top: 13px solid #ffc107;
  position: absolute;
  left: -13px;
  top: 0;
}

.taglabel.taglabel-danger {
  background-color: #f03d4f;
}

.taglabel.taglabel-danger:after {
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #f03d4f;
}

.taglabel.taglabel-danger:before {
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
  border-top: 13px solid #f03d4f;
}

.taglabel.taglabel-primary {
  background-color: #0d6efd;
}

.taglabel.taglabel-primary:after {
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #0d6efd;
}

.taglabel.taglabel-primary:before {
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
  border-top: 13px solid #0d6efd;
}

.taglabel.taglabel-secondary {
  background-color: #0e0c0c;
}

.taglabel.taglabel-secondary:after {
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #0e0c0c;
}

.taglabel.taglabel-secondary:before {
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
  border-top: 13px solid #0e0c0c;
}

.taglabel.taglabel-success {
  background-color: #21E985;
}

.taglabel.taglabel-success:after {
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #21E985;
}

.taglabel.taglabel-success:before {
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
  border-top: 13px solid #21E985;
}

.taglabel.taglabel-info {
  background-color: #00C2FF;
}

.taglabel.taglabel-info:after {
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #ffc107;
}

.taglabel.taglabel-info:before {
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
  border-top: 13px solid #ffc107;
}

.taglabel.top-0 {
  margin-top: 15px;
}

.taglabel.bottom-0 {
  margin-bottom: 15px;
}

.taglabel.start-0 {
  margin-left: -5px;
  margin-right: 0;
}

.taglabel.start-0:after {
  border-left: none;
  border-right: 13px solid transparent;
  left: auto;
  right: -13px;
}

.taglabel.start-0:before {
  border-left: none;
  border-right: 13px solid transparent;
  left: auto;
  right: -13px;
}

.taglabel.end-0 {
  margin-right: -5px;
}

/* 22. Tahank you */
/* thank you*/
.imgbottom {
  margin-top: -300px;
}

.imgbottom .imgtop {
  height: 340px;
  margin: 0 auto;
  position: relative;
  max-width: 320px;
  width: 100%;
}

@media screen and (max-height: 800px) {
  .imgbottom {
    margin-top: -170px;
  }

  .imgbottom .imgtop {
    height: 200px;
  }
}

/* 23. RTL layout */
.rtl {
  direction: rtl;
  font-family: Arial, Helvetica, sans-serif;
}

.rtl .tooltip-btn {
  left: 5px;
  right: auto;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .dropdown-menu {
  text-align: right;
}

.rtl .toast-header .btn-close {
  margin-left: -0.375rem;
  margin-right: 0.75rem;
}

.rtl .me-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ps-0 {
  padding-left: var(--oneuiux-padding) !important;
  padding-right: 0;
}

.rtl .pe-0 {
  padding-right: var(--oneuiux-padding) !important;
  padding-left: 0;
}

.rtl .float-end {
  float: left !important;
}

.rtl .start-auto {
  left: 0 !important;
  right: auto !important;
}

.rtl .text-end {
  text-align: left !important;
}

.rtl .form-floating>label {
  left: auto;
  right: 0;
}

.rtl .Toastify__toast-body>div:last-child {
  text-align: right;
}

.rtl .sidebar-wrap {
  left: auto;
  right: -100%;
}

.rtl .sidebar-wrap .closemenu {
  right: auto;
  left: -25px;
}

.rtl .sidebar-wrap .sidebar {
  float: right;
}

.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link>.icon {
  margin-left: 15px;
  margin-right: 0;
}

.rtl .sidebar-wrap.sidebar-overlay .sidebar {
  left: auto;
  right: 0;
}

.rtl .sidebar-wrap.sidebar-fullmenu .closemenu {
  left: 15px;
  right: auto;
}

.rtl .sidebar-wrap.sidebar-fullmenu .nav .nav-item .nav-link>.icon {
  margin-left: auto;
  margin-right: auto;
}

.rtl.menu-open .sidebar-wrap {
  right: 0;
}

.rtl.menu-open .sidebar-wrap.sidebar-pushcontent+main {
  margin-left: auto;
  transform: scale(1);
  margin-right: 280px;
}

.rtl .summayswiper .swiper-wrapper .swiper-slide:first-child,
.rtl .cardswiper .swiper-wrapper .swiper-slide:first-child,
.rtl .connectionwiper .swiper-wrapper .swiper-slide:first-child,
.rtl .summayswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: 5px;
  padding-right: var(--oneuiux-padding);
}

.rtl .summayswiper .swiper-wrapper .swiper-slide:last-child,
.rtl .cardswiper .swiper-wrapper .swiper-slide:last-child,
.rtl .connectionwiper .swiper-wrapper .swiper-slide:last-child,
.rtl .summayswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: 5px;
  padding-left: var(--oneuiux-padding);
}

.rtl ol,
.rtl ul {
  padding-right: 0;
}

/* 24. Custom */
.price-text {
  font-weight: 600;
}

.font-weight-600 {
  font-weight: 600;
}

.Toastify__toast-container--top-right {
  top: 0em;
  right: 0em;
}

.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}

.rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked)>label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked)>label:before {
  content: '★ ';
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}

/*# sourceMappingURL=style.css.map */

.dashedBorder {
  border: 1px dashed;
  padding: 7px;
  text-transform: uppercase;
  font-weight: bold;
}

.close-btn {
  top: -9px;
  left: 2px;
}

.rtl .close-btn {
  top: -9px;
  right: 2px;
}

.fixed-btn {
  width: 100px;
}

@media screen and (max-width: 350px) {
  .fixed-btn {
    width: 80px;
    padding-inline: 0;
  }
}

.leaflet-control-attribution.leaflet-control a {
  display: none;
}

.rtl .modal-header .btn-close {
  margin: 0;
}

.font-large {
  font-size: 25px !important;
}

.max-container {
  height: 100%;
}

.splash-img {
  /* padding: 0px 5px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  /* margin-inline: 4px; */
  margin-left: 3px;
  position: relative;
}

.rtl .image-overlay {
  margin-left: 0;
  margin-right: 3px;
}

.zIndex-9 {
  z-index: 9;
}

.image-default {
  margin-left: 3px;
}

.rtl .image-default {
  margin-left: 0;
  margin-right: 3px;
}

.image-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--oneuiux-theme-color);
  z-index: 1;
  opacity: 0.35;
}

.scrollable-column {
  flex: 1 1;
  height: 100%;
  overflow-y: scroll;
}

.color-primary {
  color: var(--oneuiux-theme-color);
}

.social-media {
  position: absolute;
  z-index: 99;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding: 20px;
  width: max-content;
  box-sizing: border-box;
}

.social-media-x {
  position: static !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  left: unset;
  bottom: unset;
  transform: none;
  padding: 0;
  box-sizing: content-box;
}

.ads-container .splash-img {
  height: 220px;
  border-radius: 25px;
  object-fit: cover;
}

.pt-lg {
  padding-top: 129px;
}


@media screen and (max-width: 993px) {
  body {
    overflow-y: auto;
  }

  .splash-img {
    height: 220px;
    margin-top: 135px;
    border-radius: 25px;
  }

  .max-container {
    flex-direction: column-reverse;
    height: auto;
  }

  .header {
    width: 100%;
    min-width: auto;
  }

  .footer {
    width: 100%;
  }

  .image-overlay {
    margin-inline: 15px !important;
    margin-bottom: 10px;
  }

  .image-default {
    margin-inline: 15px !important;
    margin-bottom: 10px;
  }

  .image-overlay::before {
    top: 135px;
    height: 220px;
    border-radius: 25px;
  }

  .scrollable-column {
    flex: 1 1;
    overflow-y: unset;
  }

  .social-media {
    position: static !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    left: unset;
    bottom: unset;
    transform: none;
    padding: 0;
    box-sizing: content-box;
  }
}

@media screen and (max-width: 411px) {
  .no-padding-sm {
    padding: 0;
  }

  .no-padding-sm .sm {
    margin-inline: 5px;
  }
}

.outofstock {
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 100px;
  z-index: 1;
}

.rtl .outofstock {
  position: absolute;
  top: 8px;
  right: 8px;
  left: unset;
  border-radius: 100px;
  z-index: 1;
}

.centered-logo {
  position: absolute;
  z-index: 99;
  border-radius: 100%;
  height: 175px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 25px;
  background-color: white;
  width: max-content;
  box-sizing: border-box;
}

.profile-menu-container .submenu {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: 100%;
  right: 0;
  margin-top: 5px;
}

.rtl .profile-menu-container .submenu {
  right: unset;
  left: 0;
}

.profile-menu-container .submenu button {
  text-align: start;
  margin-block: 5px;
  color: var(--oneuiux-theme-text-primary);
  font-size: 12px !important;
  text-transform: unset !important;
  padding-inline: 5px;
}

th,
td {
  color: var(--oneuiux-theme-text-primary);
}

.table {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
}

/* Show the scrollbar */
.table-wrapper::-webkit-scrollbar {
  height: 8px;
  /* Height of the horizontal scrollbar */
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Scrollbar thumb color */
  border-radius: 10px;
  /* Rounded edges for the scrollbar */
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker thumb on hover */
}

.table-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Background color of the scrollbar track */
}

.table thead tr,
.table thead tr th,
.table tbody tr,
.table tbody tr td {
  border: none;
}

.table tbody tr td {
  vertical-align: middle;
}

.table thead th:first-child {
  border-radius: 10px 0 0 10px;
}

.table thead th:last-child {
  border-radius: 0 10px 10px 0;
}

.rtl .table thead th:first-child {
  border-radius: 0 10px 10px 0;
}

.rtl .table thead th:last-child {
  border-radius: 10px 0 0 10px;
}

.rtl nav {
  direction: ltr;
}

@media (max-width: 376px) {
  .profile-btn {
    height: 32px;
    line-height: 34px;
    width: 32px;
  }
}

@media (max-width: 321px) {
  .profile-btn {
    height: 22px;
    line-height: 34px;
    width: 22px;
  }
}


.circle_point {
  width:80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

.half {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid transparent;
}



.half.gray,.half.gold {
  border-color: var(--oneuiux-theme-color-grad-3);
}


.dashed-hr {
  border: none; 
  border-top: 2px dashed white; 
  width: 100%; 
  opacity: 1;
}
.card.bg-color-system{
  border-radius: 10px;
}
/* var(--oneuiux-theme-color-grad-1) */
/* #b8860b */
.use_vouchers,.use_vouchers:hover{
  background-color:white;
  border: 1px solid var(--oneuiux-theme-color-grad-1);
  color: var(--oneuiux-theme-color-grad-3) !important;
}

.use_vouchers h6{
 color: var(--oneuiux-theme-color-grad-3) !important;
}