.swiper-container {
    z-index: 0;
}

.swiper-pagination {
    .swiper-pagination-bullet {
        background-color: var(--oneuiux-theme-color);
    }

    &.white {
        .swiper-pagination-bullet {
            background-color: $white;
            opacity: 0.6;
        }

        .swiper-pagination-bullet-active {
            background-color: var(--oneuiux-theme-color);
            opacity: 1;
        }
    }
}

.pagination-smallline {
    .swiper-pagination-bullet {
        height: 4px;
        width: 10px;
        border-radius: 2px;
    }
}

.pagination-rightnumber {
    position: absolute;
    top: 45%;
    right: 15px;
    left: auto !important;
    width: auto;
    text-align: right;

    .swiper-pagination-bullet {
        background: none !important;
        color: var(--oneuiux-theme-text-secondary);
        height: 30px;
        line-height: 30px;
        position: relative;
        display: block;
        width: 100%;
        opacity: 0.5;

        &::after {
            content: "";
            height: 2px;
            width: 15px;
            border-radius: 2px;
            background-color: var(--oneuiux-theme-text-secondary);
            display: block;
            position: absolute;
            right: 15px;
            top: 49%;
        }

        &.swiper-pagination-bullet-active {
            color: var(--oneuiux-theme-color);
            opacity: 1;

            &:after {
                width: 30px;
                background-color: var(--oneuiux-theme-color);
            }
        }
    }
}

.introswiper {
    height: 100%;

    .swiper-wrapper {
        .swiper-slide {
            padding-left: var(--oneuiux-padding);
            padding-right: var(--oneuiux-padding);
            overflow: hidden;
        }
    }
}

.introswiperbottom {
    height: auto;
    margin-top: -300px;

    .swiper-wrapper {
        .swiper-slide {
            padding: 0px var(--oneuiux-padding) 65px var(--oneuiux-padding);
            overflow: hidden;

            .imgtop {
                height: 340px;
                margin: 0 auto;
                position: relative;
                max-width: 320px;
                width: 100%;
            }
        }
    }
}

.cardswiper {
    .swiper-wrapper {
        .swiper-slide {
            width: 280px;
            padding: 0 5px 10px 15px;

            &:last-child {
                padding-right: var(--oneuiux-padding);
            }

            &:first-child {
                padding-left: var(--oneuiux-padding);
            }

            .card {
                min-height: 150px;
            }
        }
    }
}

.scrollx {
    .swiper-wrapper {
        .swiper-slide {
            width: auto;
            padding: 0 10px 0px 0px;

            .number {
                padding: 12px;
            }
        }
    }
}

.connectionwiper {
    .swiper-wrapper {
        .swiper-slide {
            width: auto;
            padding: 0 5px 10px 15px;

            &:last-child {
                padding-right: var(--oneuiux-padding);
            }

            &:first-child {
                padding-left: var(--oneuiux-padding);
            }
        }
    }
}

.blogswiper {
    .swiper-wrapper {
        .swiper-slide {
            width: auto;
            padding: 0;

        }
    }
}

.summayswiper {
    .swiper-wrapper {
        .swiper-slide {
            width: 200px;
            padding: 0 5px 10px 15px;

            &:last-child {
                padding-right: var(--oneuiux-padding);
            }

            &:first-child {
                padding-left: var(--oneuiux-padding);
            }
        }
    }
}

.tagsswiper {
    .swiper-wrapper {
        .swiper-slide {
            width: auto;
            padding: 0 5px 10px 5px;

            &:last-child {
                padding-right: var(--oneuiux-padding);
            }

            &:first-child {
                padding-left: var(--oneuiux-padding);
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .introswiper {
        .slidebg {
            max-height: 150px;
        }

        .swiper-slide {
            .slideimg {
                max-height: 200px;
            }
        }

        .swiper-slide-two {
            .slideimg {
                max-height: 120px;
            }
        }

        .swiper-slide-three {
            .slideimg {
                max-height: 70px;
            }

            .slideimg2 {
                max-height: 180px;
            }
        }
    }


}

@media screen and (max-height: 800px) {
    .introswiperbottom {
        height: auto;
        margin-top: -170px;

        .swiper-wrapper {
            .swiper-slide {
                padding: 0px var(--oneuiux-padding) 45px var(--oneuiux-padding);

                .imgtop {
                    height: 200px;
                }
            }
        }
    }
}